import Container from "../components/Container";
import Footer from "../components/Footer";
import Main from "../components/Main";
import NotFound from "../components/NotFound";

const NotFoundPage = () => {
   return ( 
      <>
         <Main>
            <Container>
               <NotFound />
            </Container>
         </Main>
         <Footer />
      </>
   );
}
 
export default NotFoundPage;