import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import Loader from "./Loader";

const StyledLoadableImage = styled.div` 
   img {
      opacity: 0;
      transition: ${props => props.isClicked ? 'opacity .5s linear' : 'none'};

      &.loadedImage {
         opacity: 1;
      }
   }

   .loader {
      position: fixed;
      z-index: 1000;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; 
   }
`;

const LoadableImage = ({src, alt, ...props}) => {
   const [isLoaded, setIsLoaded] = useState(false);
   const imageRef = useRef(null);

   useEffect(() => {
      if (imageRef.current) {
         imageRef.current.onload = () => setIsLoaded(true);
      }
   }, []);

   useEffect(() => {
      setIsLoaded(false);
   }, [src]);

   return ( 
      <StyledLoadableImage {...props}>
         <img 
            src={src} 
            alt={alt} 
            ref={imageRef}
            className={isLoaded ? 'loadedImage' : ''}
         />
         {!isLoaded && <Loader className="loader" />}
      </StyledLoadableImage>
   );
}
 
export default LoadableImage;