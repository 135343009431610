import Container from "../components/Container";
import Footer from "../components/Footer";
import Main from "../components/Main";
import Bio from "../components/Bio";

const BioPage = () => {
   return ( 
      <>
         <Main>
            <Container>
               <Bio />
            </Container>  
         </Main>
         <Footer />
      </>
   );
}
 
export default BioPage;