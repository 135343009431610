import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
   apiKey: "AIzaSyDNsVVBsvT_1jO_JuV3Xf71SQzCuFtZ-aQ",
   authDomain: "rongarrigues-d9914.firebaseapp.com",
   projectId: "rongarrigues-d9914",
   storageBucket: "rongarrigues-d9914.appspot.com",
   messagingSenderId: "966792566149",
   appId: "1:966792566149:web:702b553429c9781c90a0ec",
   measurementId: "G-NNZ7R0ZBVB"
 };

initializeApp(firebaseConfig);

const functions = getFunctions();

export const checkReCAPTCHA = httpsCallable(functions, 'checkReCAPTCHA');
export const sendEmail = httpsCallable(functions, 'sendEmail');
