import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const StyledMyNavLink = styled(NavLink).attrs(({className}) => ({
   className
}))`
   cursor: pointer;
   color: ${({theme}) => theme.colors.primary};
   font-weight: ${({theme}) => theme.fonts.weight};
   font-size: ${({theme}) => theme.fonts.size};
   line-height: 19px;
   letter-spacing: 1px;
   user-select: none;
   transition: color .2s;

   @media (hover: hover) {
      &:hover {
         color: ${({theme}) => theme.colors.secondary};
      }
   }

   &.active {
      color: ${({theme}) => theme.colors.secondary};
   }
   
   &.my-menu-navlink {
      font-weight: 100;
      font-size: 30px;
      line-height: 35px;
   }

   &.submenu-navlink {
      font-size: ${({theme}) => theme.fonts.size};
      line-height: 19px;
   }
`;

const MyNavLink = props => {
   return ( 
      <StyledMyNavLink {...props} />
   );
}
 
export default MyNavLink;