import styled from "styled-components";

const StyledContainer = styled.div`
   width: 100%;
   max-width: 1280px;
   padding: 0 20px;
   margin: 0 auto; 

   @media ${props => props.theme.media.tablet} {
      padding: 0 40px;
   }

   @media ${props => props.theme.media.desktop} {
      padding: 0 87px;
   }
`;

const Container = props => {
   return ( 
      <StyledContainer {...props} />
   );
}
 
export default Container;