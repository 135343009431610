import styled from "styled-components";
import contact from "../assets/contact.jpg";
import MyButton from "./UI/MyButton";
import MyInput from "./UI/MyInput";
import MyTextarea from "./UI/MyTextarea";
import { useForm } from "react-hook-form";
import { checkReCAPTCHA, sendEmail } from "../firebase-config.js";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useCallback, useState } from "react";
import { SpinnerCircularFixed } from 'spinners-react';

const StyledContact = styled.div`
   .contact-image-form-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 55px;
      color: ${({theme}) => theme.colors.primary};
      font-weight: ${({theme}) => theme.fonts.weight};
      font-size: ${({theme}) => theme.fonts.size};
      line-height: ${({theme}) => theme.fonts.lineHeight};

      @media (min-width: 992px) {
         flex-direction: row;
         justify-content: space-around;
         align-items: flex-start;
      }
   }

   .contact-image-wrapper {
      margin-bottom: 35px;

      @media ${({theme}) => theme.media.tablet} {
         margin-left: auto;
         margin-right: auto;
      }

      @media (min-width: 992px) {
         flex: 0 0 auto;
         margin-bottom: 0;
         margin-left: 0;
         margin-right: 50px;
      }
   }

   img {
      display: block;
      width: 100%;
      height: 100%;

      @media ${({theme}) => theme.media.tablet} {
         display: inline-block;
         width: auto;
         height: 400px;
      }
   }

   form {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 482px;
      margin: 0 auto;

      @media (min-width: 992px) {
         margin: 0;
      }

      h2 {
         margin-bottom: 20px;
      }

      > div {
         margin-bottom: 10px;
      }

      > div:last-of-type {
         margin-bottom: 40px;
      }

      .form-validation-error {
        margin-top: 3px;
        color: #ff4b4b;
        font-size: 13px;
      }

      span {
        margin-left: 10px;
      }

      .reCAPTCHA {
        margin-top: 10px;
        color: #ccc;
        font-size: 12px;
      }
   }
`;

const Contact = (props) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("submit");
    return checkReCAPTCHA(token);
  }, [executeRecaptcha]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const notifySuccess = () =>
    toast.success("Your message was sent successfully!");

  const notifyWarning = () =>
    toast.warning("Something went wrong. Please try again later.");

  const onSubmit = async (data) => {
    setIsDisabled((actual) => !actual);

    try {
      const reCaptchaResult = await handleReCaptchaVerify();

      if (
        reCaptchaResult.data.action === "submit" &&
        reCaptchaResult.data.success &&
        reCaptchaResult.data.score > 0.5
      ) {
        await sendEmail(data);
        notifySuccess();
      } else {
        notifyWarning();
      }
    } catch (error) {
      notifyWarning();
    } finally {
      reset();
      setIsDisabled((actual) => !actual);
    }
  };

  return (
    <StyledContact {...props}>
        <div className="contact-image-form-wrapper">
          <div className="contact-image-wrapper">
              <img src={contact} alt="contact" />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Get in touch</h2>
            <div>
              <MyInput
                type="text"
                placeholder="Your name"
                autoComplete="off"
                {...register("your-name", { required: true })}
              />
              {errors["your-name"] && <p className="form-validation-error">Please enter your name</p>}
            </div>
            <div>
              <MyInput
                type="email"
                placeholder="Email"
                autoComplete="off"
                {...register("email", { required: true })}
              />
              {errors["email"] && <p className="form-validation-error">Please enter your email</p>}
            </div>
            <div>
              <MyInput
                type="tel"
                placeholder="Phone"
                autoComplete="off"
                {...register("phone", {})}
              />
            </div>
            <div>
              <MyTextarea
                placeholder="Message"
                {...register("message", { required: true })}
              />
              {errors["message"] && <p className="form-validation-error">Please enter your message</p>}
            </div>
            <MyButton type="submit" disabled={isDisabled}>
              {isDisabled 
                ? 
                  <>
                    <SpinnerCircularFixed 
                      size={18} 
                      thickness={180} 
                      speed={100} 
                      color="#222" 
                      secondaryColor="rgba(255, 255, 255, 1)" 
                    />
                    <span>Sending...</span>
                  </>
                :
                  'Submit'
              }
            </MyButton>
            <p className="reCAPTCHA">
              This site is protected by reCAPTCHA and the Google{" "}
              <a target="_blank" rel="noreferrer" href="https://policies.google.com/privacy">
                Privacy Policy
              </a>{" "}and{" "}
              <a target="_blank" rel="noreferrer" href="https://policies.google.com/terms">
                Terms of Service
              </a>{" "}apply.
            </p>
          </form>
        </div>
        <ToastContainer
          position="bottom-right"
          hideProgressBar
          transition={Slide}
        />
    </StyledContact>
  );
};

export default Contact;
