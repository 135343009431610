import React from "react";
import styled from "styled-components";

const StyledMyInput = styled.input`
   display: block;
   width: 100%;
   padding: 10px 15px;
   border: 1px solid #fff;
   background-color: #000;
   color: ${({theme}) => theme.colors.primary};
   font-family: ${({theme}) => theme.fonts.family};
   font-size: 14px;

   &::placeholder {
      color: ${({theme}) => theme.colors.primary};
   }

   &:focus::placeholder {
      color: transparent;
   }
`;

const MyInput = React.forwardRef((props, ref) => {
   return <StyledMyInput {...props} ref={ref} />;
});

export default MyInput;
