import React from "react";
import styled from "styled-components";

const StyledMyButton = styled.button`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 60px;
   padding: 20px;
   border: none;
   background-color: #ccc;
   color: #000;
   text-transform: uppercase;
   font-family: ${({theme}) => theme.fonts.family};
   font-weight: 400;
   font-size: 14px;
   letter-spacing: 2px;
   cursor: pointer;
   transition: all .2s;

   @media (hover: hover) {
      &:enabled:hover {
         background-color: #222;
         color: ${({theme}) => theme.colors.primary};
      }
   }

   &:disabled {
      background-color: #222;
      color: ${({theme}) => theme.colors.primary};
      cursor: auto;
   }
`;

const MyButton = React.forwardRef((props, ref) => {
   return <StyledMyButton {...props} ref={ref} />;
});

export default MyButton;
