import styled from "styled-components";

const StyledNotFound = styled.div`
   padding-top: 100px;
   text-align: center;
   height: 100%;
   color: ${({theme}) => theme.colors.primary};
   font-weight: ${({theme}) => theme.fonts.weight};
   font-size: 24px;
   line-height: 30px;

   > div {
      margin-bottom: 20px;
   }
`;

const NotFound = props => {
   return ( 
      <StyledNotFound {...props}>
         <div>404</div>
         <div>Page Not Found</div>
      </StyledNotFound>
   );
}
 
export default NotFound;