import artwork_1_1 from "./assets/assemblages/assemblages-500/1_7_22_garrigues_archive18472.jpg";
import artwork_1_2 from "./assets/assemblages/assemblages-big/1_7_22_garrigues_archive18472.jpg";

import artwork_2_1 from "./assets/assemblages/assemblages-500/1_7_22_garrigues_archive18477.jpg";
import artwork_2_2 from "./assets/assemblages/assemblages-big/1_7_22_garrigues_archive18477.jpg";

import artwork_3_1 from "./assets/assemblages/assemblages-500/1_7_22_garrigues_archive18489.jpg";
import artwork_3_2 from "./assets/assemblages/assemblages-big/1_7_22_garrigues_archive18489.jpg";

import artwork_4_1 from "./assets/assemblages/assemblages-500/1_7_22_garrigues_archive18556.jpg";
import artwork_4_2 from "./assets/assemblages/assemblages-big/1_7_22_garrigues_archive18556.jpg";

import artwork_5_1 from "./assets/assemblages/assemblages-500/1_7_22_garrigues_archive18561.jpg";
import artwork_5_2 from "./assets/assemblages/assemblages-big/1_7_22_garrigues_archive18561.jpg";

import artwork_6_1 from "./assets/assemblages/assemblages-500/1_7_22_garrigues_archive18564.jpg";
import artwork_6_2 from "./assets/assemblages/assemblages-big/1_7_22_garrigues_archive18564.jpg";

import artwork_7_1 from "./assets/assemblages/assemblages-500/1_7_22_garrigues_archive18566.jpg";
import artwork_7_2 from "./assets/assemblages/assemblages-big/1_7_22_garrigues_archive18566.jpg";

import artwork_8_1 from "./assets/assemblages/assemblages-500/1_7_22_garrigues_archive18568.jpg";
import artwork_8_2 from "./assets/assemblages/assemblages-big/1_7_22_garrigues_archive18568.jpg";

import artwork_9_1 from "./assets/assemblages/assemblages-500/1_7_22_garrigues_archive18575.jpg";
import artwork_9_2 from "./assets/assemblages/assemblages-big/1_7_22_garrigues_archive18575.jpg";

import artwork_10_1 from "./assets/assemblages/assemblages-500/1_7_22_garrigues_archive18583.jpg";
import artwork_10_2 from "./assets/assemblages/assemblages-big/1_7_22_garrigues_archive18583.jpg";

import artwork_11_1 from "./assets/assemblages/assemblages-500/1_7_22_garrigues_archive18597.jpg";
import artwork_11_2 from "./assets/assemblages/assemblages-big/1_7_22_garrigues_archive18597.jpg";

import artwork_12_1 from "./assets/assemblages/assemblages-500/1_7_22_garrigues_archive18605.jpg";
import artwork_12_2 from "./assets/assemblages/assemblages-big/1_7_22_garrigues_archive18605.jpg";

import artwork_13_1 from "./assets/assemblages/assemblages-500/16706a.jpg";
import artwork_13_2 from "./assets/assemblages/assemblages-big/16706a.jpg";

import artwork_14_1 from "./assets/assemblages/assemblages-500/16719a.jpg";
import artwork_14_2 from "./assets/assemblages/assemblages-big/16719a.jpg";

import artwork_15_1 from "./assets/assemblages/assemblages-500/16732a.jpg";
import artwork_15_2 from "./assets/assemblages/assemblages-big/16732a.jpg";

import artwork_16_1 from "./assets/assemblages/assemblages-500/assemblage_3.jpg";
import artwork_16_2 from "./assets/assemblages/assemblages-big/assemblage_3.jpg";

import artwork_17_1 from "./assets/assemblages/assemblages-500/gar_78a.jpg";
import artwork_17_2 from "./assets/assemblages/assemblages-big/gar_78a.jpg";

import artwork_18_1 from "./assets/assemblages/assemblages-500/gar_78b.jpg";
import artwork_18_2 from "./assets/assemblages/assemblages-big/gar_78b.jpg";

import artwork_19_1 from "./assets/assemblages/assemblages-500/gar_81a.jpg";
import artwork_19_2 from "./assets/assemblages/assemblages-big/gar_81a.jpg";

import artwork_20_1 from "./assets/assemblages/assemblages-500/gar_84a.jpg";
import artwork_20_2 from "./assets/assemblages/assemblages-big/gar_84a.jpg";

import artwork_21_1 from "./assets/assemblages/assemblages-500/gar_89b.jpg";
import artwork_21_2 from "./assets/assemblages/assemblages-big/gar_89b.jpg";

import artwork_22_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_1.jpg";
import artwork_22_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_1.jpg";

import artwork_23_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_2.jpg";
import artwork_23_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_2.jpg";

import artwork_24_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_5.jpg";
import artwork_24_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_5.jpg";

import artwork_25_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_7.jpg";
import artwork_25_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_7.jpg";

import artwork_26_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_10.jpg";
import artwork_26_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_10.jpg";

import artwork_27_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_11.jpg";
import artwork_27_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_11.jpg";

import artwork_28_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_12.jpg";
import artwork_28_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_12.jpg";

import artwork_29_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_13.jpg";
import artwork_29_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_13.jpg";

import artwork_30_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_14_2.jpg";
import artwork_30_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_14_2.jpg";

import artwork_31_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_15_2.jpg";
import artwork_31_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_15_2.jpg";

import artwork_32_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_16.jpg";
import artwork_32_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_16.jpg";

import artwork_33_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_17.jpg";
import artwork_33_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_17.jpg";

import artwork_34_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_18.jpg";
import artwork_34_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_18.jpg";

import artwork_35_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_20.jpg";
import artwork_35_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_20.jpg";

import artwork_36_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_21.jpg";
import artwork_36_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_21.jpg";

import artwork_37_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_22.jpg";
import artwork_37_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_22.jpg";

import artwork_38_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_23.jpg";
import artwork_38_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_23.jpg";

import artwork_39_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_24.jpg";
import artwork_39_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_24.jpg";

import artwork_40_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_26.jpg";
import artwork_40_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_26.jpg";

import artwork_41_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_28.jpg";
import artwork_41_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_28.jpg";

import artwork_42_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_29.jpg";
import artwork_42_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_29.jpg";

import artwork_43_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_30.jpg";
import artwork_43_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_30.jpg";

import artwork_44_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_31.jpg";
import artwork_44_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_31.jpg";

import artwork_45_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_32.jpg";
import artwork_45_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_32.jpg";

import artwork_46_1 from "./assets/etchings-on-clayboard/etchings-on-clayboard-500/big_33.jpg";
import artwork_46_2 from "./assets/etchings-on-clayboard/etchings-on-clayboard-big/big_33.jpg";

import artwork_58_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/absolut_ron.jpg";
import artwork_58_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/absolut_ron.jpg";

import artwork_61_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/armadillo.jpg";
import artwork_61_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/armadillo.jpg";

import artwork_66_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/black_rhino.jpg";
import artwork_66_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/black_rhino.jpg";

import artwork_69_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/bowhead_whale.jpg";
import artwork_69_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/bowhead_whale.jpg";

import artwork_71_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/dead_forest_antill_anteater.jpg";
import artwork_71_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/dead_forest_antill_anteater.jpg";

import artwork_73_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/dolphin_tuna_sandwich.jpg";
import artwork_73_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/dolphin_tuna_sandwich.jpg";

import artwork_75_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/elephant_ivory.jpg";
import artwork_75_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/elephant_ivory.jpg";

import artwork_80_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/flamingo.jpg";
import artwork_80_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/flamingo.jpg";

import artwork_81_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/fossil_fuel_man.jpg";
import artwork_81_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/fossil_fuel_man.jpg";

import artwork_85_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/green_turtle.jpg";
import artwork_85_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/green_turtle.jpg";

import artwork_87_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/jaguar.jpg";
import artwork_87_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/jaguar.jpg";

import artwork_102_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/pollution_child.jpg";
import artwork_102_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/pollution_child.jpg";

import artwork_103_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/raven_oil_spill_sacrifice.jpg";
import artwork_103_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/raven_oil_spill_sacrifice.jpg";

import artwork_106_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/salmon_river_of_no_return.jpg";
import artwork_106_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/salmon_river_of_no_return.jpg";

import artwork_107_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/scarlet_macaw.jpg";
import artwork_107_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/scarlet_macaw.jpg";

import artwork_108_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/sloth.jpg";
import artwork_108_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/sloth.jpg";

import artwork_109_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/spotted_owl.jpg";
import artwork_109_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/spotted_owl.jpg";

import artwork_111_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/tapir.jpg";
import artwork_111_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/tapir.jpg";

import artwork_120_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/wolf.jpg";
import artwork_120_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/wolf.jpg";

import artwork_47_1 from "./assets/sculptures/sculptures-500/1_7_22_garrigues_archive18450.jpg";
import artwork_47_2 from "./assets/sculptures/sculptures-big/1_7_22_garrigues_archive18450.jpg";

import artwork_48_1 from "./assets/sculptures/sculptures-500/1_7_22_garrigues_archive18462.jpg";
import artwork_48_2 from "./assets/sculptures/sculptures-big/1_7_22_garrigues_archive18462.jpg";

import artwork_49_1 from "./assets/sculptures/sculptures-500/1_7_22_garrigues_archive18486.jpg";
import artwork_49_2 from "./assets/sculptures/sculptures-big/1_7_22_garrigues_archive18486.jpg";

import artwork_50_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/1_7_22_garrigues_archive18497.jpg";
import artwork_50_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/1_7_22_garrigues_archive18497.jpg";

import artwork_51_1 from "./assets/sculptures/sculptures-500/1_7_22_garrigues_archive18500.jpg";
import artwork_51_2 from "./assets/sculptures/sculptures-big/1_7_22_garrigues_archive18500.jpg";

import artwork_52_1 from "./assets/sculptures/sculptures-500/1_7_22_garrigues_archive18507.jpg";
import artwork_52_2 from "./assets/sculptures/sculptures-big/1_7_22_garrigues_archive18507.jpg";

import artwork_55_1 from "./assets/sculptures/sculptures-500/1_7_22_garrigues_archive18522.jpg";
import artwork_55_2 from "./assets/sculptures/sculptures-big/1_7_22_garrigues_archive18522.jpg";

import artwork_56_1 from "./assets/sculptures/sculptures-500/1_7_22_garrigues_archive18549.jpg";
import artwork_56_2 from "./assets/sculptures/sculptures-big/1_7_22_garrigues_archive18549.jpg";

import artwork_57_1 from "./assets/sculptures/sculptures-500/1_7_22_garrigues_archive18608.jpg";
import artwork_57_2 from "./assets/sculptures/sculptures-big/1_7_22_garrigues_archive18608.jpg";

import artwork_59_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/aeronautic_age_man.jpg";
import artwork_59_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/aeronautic_age_man.jpg";

import artwork_60_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/american_crocodile.jpg";
import artwork_60_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/american_crocodile.jpg";

import artwork_62_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/automotive_age_man.jpg";
import artwork_62_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/automotive_age_man.jpg";

import artwork_63_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/automotive_man_self_sacrifice_i.jpg";
import artwork_63_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/automotive_man_self_sacrifice_i.jpg";

import artwork_64_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/bear_asian_medicine.jpg";
import artwork_64_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/bear_asian_medicine.jpg";

import artwork_68_1 from "./assets/sculptures/sculptures-500/bone_of_mother_goddess.jpg";
import artwork_68_2 from "./assets/sculptures/sculptures-big/bone_of_mother_goddess.jpg";

import artwork_70_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/california_condor.jpg";
import artwork_70_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/california_condor.jpg";

import artwork_72_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/dicks_world.jpg";
import artwork_72_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/dicks_world.jpg";

import artwork_74_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/dr_frankencloner.jpg";
import artwork_74_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/dr_frankencloner.jpg";

import artwork_76_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/end_of_the_trail.jpg";
import artwork_76_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/end_of_the_trail.jpg";

import artwork_77_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/famine_ii_children.jpg";
import artwork_77_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/famine_ii_children.jpg";

import artwork_78_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/famine_iii.jpg";
import artwork_78_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/famine_iii.jpg";

import artwork_79_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/famine.jpg";
import artwork_79_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/famine.jpg";

import artwork_82_1 from "./assets/sculptures/sculptures-500/frau_willendorf.jpg";
import artwork_82_2 from "./assets/sculptures/sculptures-big/frau_willendorf.jpg";

import artwork_83_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/frigate.jpg";
import artwork_83_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/frigate.jpg";

import artwork_84_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/gone_fishin.jpg";
import artwork_84_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/gone_fishin.jpg";

import artwork_86_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/hornbill.jpg";
import artwork_86_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/hornbill.jpg";

import artwork_88_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/king_ludd.jpg";
import artwork_88_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/king_ludd.jpg";

import artwork_89_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/nature_of_the_beast_i.jpg";
import artwork_89_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/nature_of_the_beast_i.jpg";

import artwork_90_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/nature_of_the_beast_ii.jpg";
import artwork_90_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/nature_of_the_beast_ii.jpg";

import artwork_91_1 from "./assets/sculptures/sculptures-500/nike_i.jpg";
import artwork_91_2 from "./assets/sculptures/sculptures-big/nike_i.jpg";

import artwork_92_1 from "./assets/sculptures/sculptures-500/nike_ii.jpg";
import artwork_92_2 from "./assets/sculptures/sculptures-big/nike_ii.jpg";

import artwork_93_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/no_sir_i_dont_mean_maybe.jpg";
import artwork_93_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/no_sir_i_dont_mean_maybe.jpg";

import artwork_94_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/nocturnal_monkey.jpg";
import artwork_94_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/nocturnal_monkey.jpg";

import artwork_95_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/nuclear_war_head.jpg";
import artwork_95_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/nuclear_war_head.jpg";

import artwork_96_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/onward_christian_soldiers.jpg";
import artwork_96_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/onward_christian_soldiers.jpg";

import artwork_97_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/overpop_crux_i.jpg";
import artwork_97_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/overpop_crux_i.jpg";

import artwork_98_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/overpop_crux_ii.jpg";
import artwork_98_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/overpop_crux_ii.jpg";

import artwork_99_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/overpop_crux_iii.jpg";
import artwork_99_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/overpop_crux_iii.jpg";

import artwork_100_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/overpopulation.jpg";
import artwork_100_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/overpopulation.jpg";

import artwork_101_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/platypus.jpg";
import artwork_101_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/platypus.jpg";

import artwork_104_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/rising_waters_i_climate_change.jpg";
import artwork_104_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/rising_waters_i_climate_change.jpg";

import artwork_105_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/rising_waters_ii.jpg";
import artwork_105_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/rising_waters_ii.jpg";

import artwork_110_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/stop.jpg";
import artwork_110_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/stop.jpg";

import artwork_112_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/this_land_is_our_land.jpg";
import artwork_112_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/this_land_is_our_land.jpg";

import artwork_113_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/three_birrds_paradise_lost.jpg";
import artwork_113_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/three_birrds_paradise_lost.jpg";

import artwork_114_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/transglobal_virus_i.jpg";
import artwork_114_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/transglobal_virus_i.jpg";

import artwork_115_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/transglobal_virus_ii.jpg";
import artwork_115_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/transglobal_virus_ii.jpg";

import artwork_116_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/two_birds_of_paradise_lost.jpg";
import artwork_116_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/two_birds_of_paradise_lost.jpg";

import artwork_117_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/ultimate_consumer.jpg";
import artwork_117_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/ultimate_consumer.jpg";

import artwork_118_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/videoanthropus.jpg";
import artwork_118_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/videoanthropus.jpg";

import artwork_119_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/which_came_last.jpg";
import artwork_119_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/which_came_last.jpg";

import artwork_121_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/yab_yum_thank_u_mum.jpg";
import artwork_121_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/yab_yum_thank_u_mum.jpg";

import artwork_122_1 from "./assets/anthropocene-skulls/anthropocene-skulls-500/yes_sir_thats_my_baby.jpg";
import artwork_122_2 from "./assets/anthropocene-skulls/anthropocene-skulls-big/yes_sir_thats_my_baby.jpg";

import artwork_123_1 from "./assets/pastels/pastels-500/1_7_22_garrigues_archive18640.jpg";
import artwork_123_2 from "./assets/pastels/pastels-big/1_7_22_garrigues_archive18640.jpg";

import artwork_124_1 from "./assets/pastels/pastels-500/1_7_22_garrigues_archive18642.jpg";
import artwork_124_2 from "./assets/pastels/pastels-big/1_7_22_garrigues_archive18642.jpg";

import artwork_125_1 from "./assets/pastels/pastels-500/img_6555_crx.jpg";
import artwork_125_2 from "./assets/pastels/pastels-big/img_6555_crx.jpg";

import artwork_126_1 from "./assets/pastels/pastels-500/ron_g_3.jpg";
import artwork_126_2 from "./assets/pastels/pastels-big/ron_g_3.jpg";

import artwork_127_1 from "./assets/pastels/pastels-500/ron_g_4.jpg";
import artwork_127_2 from "./assets/pastels/pastels-big/ron_g_4.jpg";

import artwork_128_1 from "./assets/pastels/pastels-500/ron_g_5.jpg";
import artwork_128_2 from "./assets/pastels/pastels-big/ron_g_5.jpg";

import artwork_129_1 from "./assets/pastels/pastels-500/ron_g_7.jpg";
import artwork_129_2 from "./assets/pastels/pastels-big/ron_g_7.jpg";

import artwork_130_1 from "./assets/pastels/pastels-500/ron_g_9.jpg";
import artwork_130_2 from "./assets/pastels/pastels-big/ron_g_9.jpg";

import artwork_131_1 from "./assets/pastels/pastels-500/ron_g_18.jpg";
import artwork_131_2 from "./assets/pastels/pastels-big/ron_g_18.jpg";

import artwork_132_1 from "./assets/pastels/pastels-500/ron_g_19.jpg";
import artwork_132_2 from "./assets/pastels/pastels-big/ron_g_19.jpg";

import artwork_133_1 from "./assets/pastels/pastels-500/ron_g_20.jpg";
import artwork_133_2 from "./assets/pastels/pastels-big/ron_g_20.jpg";

import artwork_134_1 from "./assets/pastels/pastels-500/ron_g_21.jpg";
import artwork_134_2 from "./assets/pastels/pastels-big/ron_g_21.jpg";

import artwork_135_1 from "./assets/pastels/pastels-500/ron_g_34.jpg";
import artwork_135_2 from "./assets/pastels/pastels-big/ron_g_34.jpg";

import artwork_136_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_1.jpg";
import artwork_136_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_1.jpg";

import artwork_137_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_2.jpg";
import artwork_137_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_2.jpg";

import artwork_138_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_3.jpg";
import artwork_138_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_3.jpg";

import artwork_139_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_4.jpg";
import artwork_139_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_4.jpg";

import artwork_140_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_5.jpg";
import artwork_140_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_5.jpg";

import artwork_141_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_6.jpg";
import artwork_141_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_6.jpg";

import artwork_142_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_7.jpg";
import artwork_142_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_7.jpg";

import artwork_143_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_8.jpg";
import artwork_143_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_8.jpg";

import artwork_144_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_9.jpg";
import artwork_144_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_9.jpg";

import artwork_145_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_10.jpg";
import artwork_145_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_10.jpg";

import artwork_146_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_11.jpg";
import artwork_146_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_11.jpg";

import artwork_147_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_12.jpg";
import artwork_147_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_12.jpg";

import artwork_148_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_13_cover.jpg";
import artwork_148_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_13_cover.jpg";

import artwork_149_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_14.jpg";
import artwork_149_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_14.jpg";

import artwork_150_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_16.jpg";
import artwork_150_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_16.jpg";

import artwork_151_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_17.jpg";
import artwork_151_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_17.jpg";

import artwork_152_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_18.jpg";
import artwork_152_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_18.jpg";

import artwork_153_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_19.jpg";
import artwork_153_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_19.jpg";

import artwork_154_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_20.jpg";
import artwork_154_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_20.jpg";

import artwork_155_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_21.jpg";
import artwork_155_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_21.jpg";

import artwork_156_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_22.jpg";
import artwork_156_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_22.jpg";

import artwork_157_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_24.jpg";
import artwork_157_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_24.jpg";

import artwork_158_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_25.jpg";
import artwork_158_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_25.jpg";

import artwork_159_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_26.jpg";
import artwork_159_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_26.jpg";

import artwork_160_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_27.jpg";
import artwork_160_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_27.jpg";

import artwork_161_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_28.jpg";
import artwork_161_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_28.jpg";

import artwork_162_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_29.jpg";
import artwork_162_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_29.jpg";

import artwork_163_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_30.jpg";
import artwork_163_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_30.jpg";

import artwork_164_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_31.jpg";
import artwork_164_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_31.jpg";

import artwork_165_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_32.jpg";
import artwork_165_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_32.jpg";

import artwork_166_1 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-500/ron_33.jpg";
import artwork_166_2 from "./assets/sumi-ink-on-paper-and-silk/sumi-ink-on-paper-and-silk-big/ron_33.jpg";

import artwork_167_1 from "./assets/drawings/drawings-500/1_7_22_garrigues_archive18627.jpg";
import artwork_167_2 from "./assets/drawings/drawings-big/1_7_22_garrigues_archive18627.jpg";

import artwork_168_1 from "./assets/drawings/drawings-500/1_7_22_garrigues_archive18633.jpg";
import artwork_168_2 from "./assets/drawings/drawings-big/1_7_22_garrigues_archive18633.jpg";

import artwork_169_1 from "./assets/drawings/drawings-500/20181219161255_00001.jpg";
import artwork_169_2 from "./assets/drawings/drawings-big/20181219161255_00001.jpg";

import artwork_170_1 from "./assets/drawings/drawings-500/20181219161547.jpg";
import artwork_170_2 from "./assets/drawings/drawings-big/20181219161547.jpg";

import artwork_171_1 from "./assets/drawings/drawings-500/20181219161652_00001.jpg";
import artwork_171_2 from "./assets/drawings/drawings-big/20181219161652_00001.jpg";

import artwork_172_1 from "./assets/drawings/drawings-500/20181219161723_00001.jpg";
import artwork_172_2 from "./assets/drawings/drawings-big/20181219161723_00001.jpg";

import artwork_173_1 from "./assets/drawings/drawings-500/20181219161755_00001.jpg";
import artwork_173_2 from "./assets/drawings/drawings-big/20181219161755_00001.jpg";

import artwork_174_1 from "./assets/drawings/drawings-500/20181219161827_00001.jpg";
import artwork_174_2 from "./assets/drawings/drawings-big/20181219161827_00001.jpg";

import artwork_175_1 from "./assets/drawings/drawings-500/20181219162406_00001.jpg";
import artwork_175_2 from "./assets/drawings/drawings-big/20181219162406_00001.jpg";

import artwork_176_1 from "./assets/drawings/drawings-500/20181219162434_00001.jpg";
import artwork_176_2 from "./assets/drawings/drawings-big/20181219162434_00001.jpg";

import artwork_177_1 from "./assets/drawings/drawings-500/20181219162518_00001.jpg";
import artwork_177_2 from "./assets/drawings/drawings-big/20181219162518_00001.jpg";

import artwork_178_1 from "./assets/drawings/drawings-500/20181219162553_00001.jpg";
import artwork_178_2 from "./assets/drawings/drawings-big/20181219162553_00001.jpg";

import artwork_179_1 from "./assets/drawings/drawings-500/20181219162621_00001.jpg";
import artwork_179_2 from "./assets/drawings/drawings-big/20181219162621_00001.jpg";

import artwork_180_1 from "./assets/drawings/drawings-500/20181219162649_00001.jpg";
import artwork_180_2 from "./assets/drawings/drawings-big/20181219162649_00001.jpg";

import artwork_181_1 from "./assets/drawings/drawings-500/20181219162717_00001.jpg";
import artwork_181_2 from "./assets/drawings/drawings-big/20181219162717_00001.jpg";

import artwork_182_1 from "./assets/drawings/drawings-500/20181219162806.jpg";
import artwork_182_2 from "./assets/drawings/drawings-big/20181219162806.jpg";

import artwork_183_1 from "./assets/drawings/drawings-500/20181219162841_00001.jpg";
import artwork_183_2 from "./assets/drawings/drawings-big/20181219162841_00001.jpg";

import artwork_184_1 from "./assets/drawings/drawings-500/20181219162911_00001.jpg";
import artwork_184_2 from "./assets/drawings/drawings-big/20181219162911_00001.jpg";

import artwork_185_1 from "./assets/drawings/drawings-500/20181219162937_00001.jpg";
import artwork_185_2 from "./assets/drawings/drawings-big/20181219162937_00001.jpg";

import artwork_186_1 from "./assets/drawings/drawings-500/20181219163003_00001.jpg";
import artwork_186_2 from "./assets/drawings/drawings-big/20181219163003_00001.jpg";

import artwork_187_1 from "./assets/drawings/drawings-500/20181219163033_00001.jpg";
import artwork_187_2 from "./assets/drawings/drawings-big/20181219163033_00001.jpg";

import artwork_188_1 from "./assets/drawings/drawings-500/20181219163059_00001.jpg";
import artwork_188_2 from "./assets/drawings/drawings-big/20181219163059_00001.jpg";

import artwork_189_1 from "./assets/drawings/drawings-500/20181219163127_00001.jpg";
import artwork_189_2 from "./assets/drawings/drawings-big/20181219163127_00001.jpg";

import artwork_190_1 from "./assets/drawings/drawings-500/20181219163152_00001.jpg";
import artwork_190_2 from "./assets/drawings/drawings-big/20181219163152_00001.jpg";

import artwork_191_1 from "./assets/drawings/drawings-500/20181219163217_00001.jpg";
import artwork_191_2 from "./assets/drawings/drawings-big/20181219163217_00001.jpg";

import artwork_192_1 from "./assets/drawings/drawings-500/20181219163354_00001.jpg";
import artwork_192_2 from "./assets/drawings/drawings-big/20181219163354_00001.jpg";

import artwork_193_1 from "./assets/drawings/drawings-500/20181219163429_00001.jpg";
import artwork_193_2 from "./assets/drawings/drawings-big/20181219163429_00001.jpg";

import artwork_194_1 from "./assets/drawings/drawings-500/20181219163455_00001.jpg";
import artwork_194_2 from "./assets/drawings/drawings-big/20181219163455_00001.jpg";

import artwork_195_1 from "./assets/drawings/drawings-500/20181219163521_00001.jpg";
import artwork_195_2 from "./assets/drawings/drawings-big/20181219163521_00001.jpg";

import artwork_196_1 from "./assets/drawings/drawings-500/20181219163546_00001.jpg";
import artwork_196_2 from "./assets/drawings/drawings-big/20181219163546_00001.jpg";

import artwork_197_1 from "./assets/drawings/drawings-500/20181219163610_00001.jpg";
import artwork_197_2 from "./assets/drawings/drawings-big/20181219163610_00001.jpg";

import artwork_198_1 from "./assets/drawings/drawings-500/20181219163633_00001.jpg";
import artwork_198_2 from "./assets/drawings/drawings-big/20181219163633_00001.jpg";

import artwork_199_1 from "./assets/drawings/drawings-500/20181219163703_00001.jpg";
import artwork_199_2 from "./assets/drawings/drawings-big/20181219163703_00001.jpg";

import artwork_200_1 from "./assets/drawings/drawings-500/20181219163733_00001.jpg";
import artwork_200_2 from "./assets/drawings/drawings-big/20181219163733_00001.jpg";

import artwork_201_1 from "./assets/drawings/drawings-500/20181219163800_00001.jpg";
import artwork_201_2 from "./assets/drawings/drawings-big/20181219163800_00001.jpg";

import artwork_202_1 from "./assets/drawings/drawings-500/20181219163827_00001.jpg";
import artwork_202_2 from "./assets/drawings/drawings-big/20181219163827_00001.jpg";

import artwork_203_1 from "./assets/drawings/drawings-500/20181219163854_00001.jpg";
import artwork_203_2 from "./assets/drawings/drawings-big/20181219163854_00001.jpg";

import artwork_204_1 from "./assets/assemblages/assemblages-500/1_7_22_garrigues_archive18607-500.jpg";
import artwork_204_2 from "./assets/assemblages/assemblages-big/1_7_22_garrigues_archive18607-1500.jpg";

export const menuItems = [
   {to: '/gallery', content: 'gallery'},
   {to: '/bio', content: 'bio'},
   {to: '/exhibitions', content: 'exhibitions'},
   {to: '/contact', content: 'contact'},
];

export const subMenuItems = [
   {
      to: 'anthropocene-skulls',
      content: 'anthropocene skulls'
   },

   {
      to: 'sculptures',
      content: 'sculptures'
   },

   {
      to: 'assemblages',
      content: 'assemblages'
   },

   {
      to: 'drawings',
      content: 'drawings'
   },

   {
      to: 'etchings-on-clayboard',
      content: 'etchings on clayboard'
   },

   {
      to: 'sumi-ink-on-paper-and-silk',
      content: 'sumi ink on paper and silk'
   },
];

export const galleryItems = [
   {
      category: 'assemblages',
      name: 'Sands of Time,',
      year: 'Year Unknown',
      material: 'Assemblage with bone, paint, metal and glass',
      dimensions: '8" x 13 ¹⁄₄" x 8" [HxWxD] (20.32 x 33.66 x 20.32 cm)',
      images: {
         image_1: artwork_1_1,
         image_2: artwork_1_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'Peyote Goddess',
      year: '2002',
      material: 'Assemblage with painted wood and bronze',
      dimensions: '14" x 6" x 6" [HxWxD] (35.56 x 15.24 x 15.24 cm)',
      images: {
         image_1: artwork_2_1,
         image_2: artwork_2_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'Unknown 1',
      year: '',
      material: '',
      dimensions: '9" x 6" x 9" [HxWxD] (22.86 x 15.24 x 22.86 cm)',
      images: {
         image_1: artwork_3_1,
         image_2: artwork_3_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'Don\'t Put All Your Eggs in One Basket',
      year: '2002',
      material: 'Assemblage with painted clay, bird feathers, glass, fur, shell and stone',
      dimensions: '6 ¹⁄₂" x 4" x 6" [HxWxD] (16.51 x 10.16 x 15.24 cm)',
      images: {
         image_1: artwork_4_1,
         image_2: artwork_4_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'What The Hell',
      year: '',
      material: 'Assemblage on plastic base with glass, bronze and porceline',
      dimensions: '6" x 3" x 3" [HxWxD] (15.24 x 7.62 x 7.62 cm)',
      images: {
         image_1: artwork_5_1,
         image_2: artwork_5_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'What The Hell',
      year: '',
      material: 'Assemblage on plastic base with glass, bronze and porceline',
      dimensions: '6" x 3" x 3" [HxWxD] (15.24 x 7.62 x 7.62 cm)',
      images: {
         image_1: artwork_6_1,
         image_2: artwork_6_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'Son of Albert',
      year: '2002',
      material: '',
      dimensions: '6 ¹⁄₂" x 4" x 4" [HxWxD] (16.51 x 10.16 x 10.16 cm)',
      images: {
         image_1: artwork_7_1,
         image_2: artwork_7_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'The Mouse That Soared',
      year: '',
      material: '',
      dimensions: '4" x 3" x 3" [HxWxD] (10.16 x 7.62 x 7.62 cm)',
      images: {
         image_1: artwork_8_1,
         image_2: artwork_8_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'Unknown 5',
      year: '2002',
      material: '',
      dimensions: '',
      images: {
         image_1: artwork_9_1,
         image_2: artwork_9_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'Whoo Honors Their Mother is Wise Indeed',
      year: '2002',
      material: '',
      dimensions: '5" x 5" x 5" [HxWxD] (12.7 x 12.7 x 12.7 cm)',
      images: {
         image_1: artwork_10_1,
         image_2: artwork_10_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'Unknown 3',
      year: '',
      material: '',
      dimensions: '',
      images: {
         image_1: artwork_11_1,
         image_2: artwork_11_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'Unknown 2',
      year: '',
      material: '',
      dimensions: '',
      images: {
         image_1: artwork_12_1,
         image_2: artwork_12_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'The Marlboro Man, Who Died of Lung Cancer',
      year: '',
      material: 'Assemblage with bronze cross, cigarettes and plastic',
      dimensions: '13 ¹⁄₂" x 8" x 1" [HxWxD] (34.29 x 20.32 x 2.54 cm)',
      images: {
         image_1: artwork_13_1,
         image_2: artwork_13_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'The International Eyeball Theft of the Privacy of Everyone',
      year: '2002',
      material: '',
      dimensions: '5 ¹⁄₄" x 3 ¹⁄₂" x 3 ¹⁄₂" [HxWxD] (13.34 x 8.89 x 8.89 cm)',
      images: {
         image_1: artwork_14_1,
         image_2: artwork_14_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'What The Hell',
      year: '',
      material: 'Assemblage on plastic base with glass, bronze and porceline',
      dimensions: '6" x 3" x 3" [HxWxD] (15.24 x 7.62 x 7.62 cm)',
      images: {
         image_1: artwork_15_1,
         image_2: artwork_15_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'Miami Dolphin',
      year: '2002',
      material: 'Assemblage with painted clay',
      dimensions: '9 ¹⁄₂" x 4" x 3" [HxWxD] (24.13 x 10.16 x 7.62 cm)',
      images: {
         image_1: artwork_16_1,
         image_2: artwork_16_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'Boule of Pleasure',
      year: '1992',
      material: 'Assemblage with Bronze and Wood',
      dimensions: '22" x 7" x 7" [HxWxD] (55.88 x 17.78 x 17.78 cm)',
      images: {
         image_1: artwork_17_1,
         image_2: artwork_17_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'Cape Canaveral Unleashed',
      year: 'Year Unknown',
      material: 'Cast Bronze with Paint',
      dimensions: '10" x 7" x 15" [HxWxD] (25.4 x 17.78 x 38.1 cm)',
      images: {
         image_1: artwork_18_1,
         image_2: artwork_18_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'Artificial Intelligence',
      year: 'Year Unknown',
      material: 'Skull with paint and electronic elements',
      dimensions: '7" x 5" x 8 ¹⁄₄" [HxWxD] (17.78 x 12.7 x 20.96 cm)',
      images: {
         image_1: artwork_19_1,
         image_2: artwork_19_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'The Piercing of Clitorian and Albert',
      year: '2002',
      material: 'Assemblage with painted wood, stone and tobacco tin',
      dimensions: '4 ¹⁄₂" x 3 ¹⁄₂" x 4 ¹⁄₂" [HxWxD] (11.43 x 8.89 x 11.43 cm)',
      images: {
         image_1: artwork_20_1,
         image_2: artwork_20_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'name',
      year: 'year',
      material: 'material',
      dimensions: 'dimensions',
      images: {
         image_1: artwork_21_1,
         image_2: artwork_21_2,
         alt: 'assemblage'
      }
   },
   {
      category: 'assemblages',
      name: 'It Won\'t Fit',
      year: '',
      material: 'Assemblage with painted wood',
      dimensions: '8 ¹⁄₂" x 4" x 4" [HxWxD] (21.59 x 10.16 x 10.16 cm)',
      images: {
         image_1: artwork_204_1,
         image_2: artwork_204_2,
         alt: 'assemblage'
      }
   },

   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 1',
      year: '',
      material: 'Etching on Clayboard',
      dimensions: '',
      images: {
         image_1: artwork_22_1,
         image_2: artwork_22_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 2',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '7" x 5" [HxW] (17.78 x 12.7 cm)',
      images: {
         image_1: artwork_23_1,
         image_2: artwork_23_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 5',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '7" x 5" [HxW] (17.78 x 12.7 cm)',
      images: {
         image_1: artwork_24_1,
         image_2: artwork_24_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 7',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '7" x 5" [HxW] (17.78 x 12.7 cm)',
      images: {
         image_1: artwork_25_1,
         image_2: artwork_25_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 10',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '11" x 8" [HxW] (27.94 x 20.32 cm)',
      images: {
         image_1: artwork_26_1,
         image_2: artwork_26_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 11',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '12" x 9" [HxW] (30.48 x 22.86 cm)',
      images: {
         image_1: artwork_27_1,
         image_2: artwork_27_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 12',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '10" x 8" [HxW] (25.4 x 20.32 cm)',
      images: {
         image_1: artwork_28_1,
         image_2: artwork_28_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 13',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '10" x 8" [HxW] (25.4 x 20.32 cm)',
      images: {
         image_1: artwork_29_1,
         image_2: artwork_29_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 14',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '12" x 9" [HxW] (30.48 x 22.86 cm)',
      images: {
         image_1: artwork_30_1,
         image_2: artwork_30_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 15',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '12" x 9" [HxW] (30.48 x 22.86 cm)',
      images: {
         image_1: artwork_31_1,
         image_2: artwork_31_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 16',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '12" x 9" [HxW] (30.48 x 22.86 cm)',
      images: {
         image_1: artwork_32_1,
         image_2: artwork_32_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 17',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '12" x 9" [HxW] (30.48 x 22.86 cm)',
      images: {
         image_1: artwork_33_1,
         image_2: artwork_33_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 18',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '12" x 9" [HxW] (30.48 x 22.86 cm)',
      images: {
         image_1: artwork_34_1,
         image_2: artwork_34_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 20',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '20" x 16" [HxW] (50.8 x 40.64 cm)',
      images: {
         image_1: artwork_35_1,
         image_2: artwork_35_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 21',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '20" x 16" [HxW] (50.8 x 40.64 cm)',
      images: {
         image_1: artwork_36_1,
         image_2: artwork_36_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 22',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '24" x 16" [HxW] (60.96 x 40.64 cm)',
      images: {
         image_1: artwork_37_1,
         image_2: artwork_37_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 23',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '24" x 16" [HxW] (60.96 x 40.64 cm)',
      images: {
         image_1: artwork_38_1,
         image_2: artwork_38_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 24',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '24" x 16" [HxW] (60.96 x 40.64 cm)',
      images: {
         image_1: artwork_39_1,
         image_2: artwork_39_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 26',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '',
      images: {
         image_1: artwork_40_1,
         image_2: artwork_40_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 28',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '',
      images: {
         image_1: artwork_41_1,
         image_2: artwork_41_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 29',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '36" x 24" [HxW] (91.44 x 60.96 cm)',
      images: {
         image_1: artwork_42_1,
         image_2: artwork_42_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 30',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '36" x 24" [HxW] (91.44 x 60.96 cm)',
      images: {
         image_1: artwork_43_1,
         image_2: artwork_43_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 31',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '36" x 24" [HxW] (91.44 x 60.96 cm)',
      images: {
         image_1: artwork_44_1,
         image_2: artwork_44_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 32',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '36" x 24" [HxW] (91.44 x 60.96 cm)',
      images: {
         image_1: artwork_45_1,
         image_2: artwork_45_2,
         alt: 'etching on clayboard'
      }
   },
   {
      category: 'etchings-on-clayboard',
      name: 'Big Bang 33',
      year: '2009',
      material: 'Etching on Clayboard',
      dimensions: '36" x 24" [HxW] (91.44 x 60.96 cm)',
      images: {
         image_1: artwork_46_1,
         image_2: artwork_46_2,
         alt: 'etching on clayboard'
      }
   },
   
   {
      category: 'sculptures',
      name: 'Unknown 1',
      year: '',
      material: 'Wood',
      dimensions: '',
      images: {
         image_1: artwork_47_1,
         image_2: artwork_47_2,
         alt: 'sculpture'
      }
   },
   {
      category: 'sculptures',
      name: 'Unknown 2',
      year: '',
      material: 'Wood',
      dimensions: '19" x 6" x 6" [HxWxD] (48.26 x 15.24 x 15.24 cm)',
      images: {
         image_1: artwork_48_1,
         image_2: artwork_48_2,
         alt: 'sculpture'
      }
   },
   {
      category: 'sculptures',
      name: 'Unknown 5',
      year: '',
      material: 'Animal scull with wood and model globe',
      dimensions: '',
      images: {
         image_1: artwork_49_1,
         image_2: artwork_49_2,
         alt: 'sculpture'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Bleached Raven Oil Spill Sacrifice',
      year: '1991',
      material: 'Raku',
      dimensions: '6" x 6" x 2 ³⁄₄" [HxWxD] (15.24 x 15.24 x 6.99 cm)',
      images: {
         image_1: artwork_50_1,
         image_2: artwork_50_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Raven - Oil Spill Sacrifice',
      year: '1991',
      material: 'Cast Bronze',
      dimensions: '6 ³⁄₄" x 17" x 8 ¹⁄₄" [HxWxD] (17.15 x 43.18 x 20.96 cm)',
      images: {
         image_1: artwork_103_1,
         image_2: artwork_103_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Scarlet Macaw - Cornfield Sacrifice',
      year: '1992',
      material: 'Cast Bronze',
      dimensions: '13 ¹⁄₂" x 5 ¹⁄₂" x 5 ¹⁄₂" [HxWxD] (34.29 x 13.97 x 13.97 cm)',
      images: {
         image_1: artwork_107_1,
         image_2: artwork_107_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Salmon -- River of No Return',
      year: '1992',
      material: 'Cast Bronze',
      dimensions: '12 ¹⁄₂" x 10" x 8 ¹⁄₂" [HxWxD] (31.75 x 25.4 x 21.59 cm)',
      images: {
         image_1: artwork_106_1,
         image_2: artwork_106_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Dead Forest, Dead Anthill, Dead Anteater',
      year: '1992',
      material: 'Cast Bronze',
      dimensions: '24" x 10" x 10" [HxWxD] (60.96 x 25.4 x 25.4 cm)',
      images: {
         image_1: artwork_71_1,
         image_2: artwork_71_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Fossil Fuel Man',
      year: '1992',
      material: 'Cast Bronze',
      dimensions: '16 ¹⁄₂" x 14" x 8 ¹⁄₄" [HxWxD] (41.91 x 35.56 x 20.96 cm)',
      images: {
         image_1: artwork_81_1,
         image_2: artwork_81_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Bowhead Whale-Dogfood and Sushi Sacrifice',
      year: '1992',
      material: 'Cast Bronze',
      dimensions: '12" x 24" x 19" [HxWxD] (30.48 x 60.96 x 48.26 cm)',
      images: {
         image_1: artwork_69_1,
         image_2: artwork_69_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Three-Toed Sloth-Green Grocer Sacrifice',
      year: '1993',
      material: 'Cast Bronze',
      dimensions: '16" x 14" x 8" [HxWxD] (40.64 x 35.56 x 20.32 cm)',
      images: {
         image_1: artwork_108_1,
         image_2: artwork_108_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Jaguar - Plantation Sacrifice',
      year: '1993',
      material: 'Cast Bronze',
      dimensions: '7 ¹⁄₄" x 15" x 7 ¹⁄₂" [HxWxD] (18.42 x 38.1 x 19.05 cm)',
      images: {
         image_1: artwork_87_1,
         image_2: artwork_87_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Flamingo - Saltpond Sacrifice',
      year: '1992',
      material: 'Cast Bronze',
      dimensions: '20 ¹⁄₄" x 15 ¹⁄₂" x 7 ¹⁄₂" [HxWxD] (51.44 x 39.37 x 19.05 cm)',
      images: {
         image_1: artwork_80_1,
         image_2: artwork_80_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Spotted Owl - Board Foot Sacrifice',
      year: '1993',
      material: 'Cast Bronze',
      dimensions: '9 ¹⁄₄" x 11 ¹⁄₄" x 11 ³⁄₄" [HxWxD] (23.5 x 28.58 x 29.85 cm)',
      images: {
         image_1: artwork_109_1,
         image_2: artwork_109_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Wolf - Silent Moon',
      year: '1993',
      material: 'Cast Bronze',
      dimensions: '16 ¹⁄₂" x 10 ¹⁄₂" x 10 ¹⁄₂" [HxWxD] (41.91 x 26.67 x 26.67 cm)',
      images: {
         image_1: artwork_120_1,
         image_2: artwork_120_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Green Turtle - Butcher Block Sacrifice',
      year: '1993',
      material: 'Cast Bronze',
      dimensions: '9 ¹⁄₄" x 14" x 10" [HxWxD] (23.5 x 35.56 x 25.4 cm)',
      images: {
         image_1: artwork_85_1,
         image_2: artwork_85_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Pollution-Age Child',
      year: '1993',
      material: 'Cast Bronze',
      dimensions: '8 ¹⁄₄" x 13" x 7 ¹⁄₂" [HxWxD] (20.96 x 33.02 x 19.05 cm)',
      images: {
         image_1: artwork_102_1,
         image_2: artwork_102_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Elephant-Ivory Sacrifice',
      year: '1993',
      material: 'Cast Bronze',
      dimensions: '17" x 28 ¹⁄₄" x 17 ¹⁄₂" [HxWxD] (43.18 x 71.76 x 44.45 cm)',
      images: {
         image_1: artwork_75_1,
         image_2: artwork_75_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Tapir - Slash and Burn Sacrifice',
      year: '1993',
      material: 'Cast Bronze',
      dimensions: '16" x 20 ³⁄₄" x 10" [HxWxD] (40.64 x 52.71 x 25.4 cm)',
      images: {
         image_1: artwork_111_1,
         image_2: artwork_111_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Dolphin - Tuna Sandwich Sacrifice',
      year: '1994',
      material: 'Cast Bronze',
      dimensions: '8" x 28 ¹⁄₄" x 17 ¹⁄₂" [HxWxD] (20.32 x 71.76 x 44.45 cm)',
      images: {
         image_1: artwork_73_1,
         image_2: artwork_73_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Black Rhino - Aphrodisiac and Dagger Handle Sacrifice',
      year: '1993',
      material: 'Cast Bronze',
      dimensions: '13" x 22" x 9" [HxWxD] (33.02 x 55.88 x 22.86 cm)',
      images: {
         image_1: artwork_66_1,
         image_2: artwork_66_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Armadillo - Fruit Cocktail Sacrifice',
      year: '1993',
      material: 'Cast Bronze',
      dimensions: '17" x 6" x 6" [HxWxD] (43.18 x 15.24 x 15.24 cm)',
      images: {
         image_1: artwork_61_1,
         image_2: artwork_61_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Hornbill - Chinese Carving Sacrifice',
      year: '1993',
      material: 'Cast Bronze',
      dimensions: '6" x 11" [HxW] (15.24 x 27.94 cm)',
      images: {
         image_1: artwork_86_1,
         image_2: artwork_86_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Bear - Asian Medicine Sacrifice',
      year: '1994',
      material: 'Cast Bronze',
      dimensions: '11" x 9 ¹⁄₂" x 9 ¹⁄₂" [HxWxD] (27.94 x 24.13 x 24.13 cm)',
      images: {
         image_1: artwork_64_1,
         image_2: artwork_64_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Nocturnal Monkey - Caffe Espresso Sacrifice',
      year: '1994',
      material: 'Cast Bronze',
      dimensions: '11 ¹⁄₄" x 7 ¹⁄₂" x 17 ¹⁄₄" [HxWxD] (28.58 x 19.05 x 43.82 cm)',
      images: {
         image_1: artwork_94_1,
         image_2: artwork_94_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Automotive-Age Man - Self Sacrifice I',
      year: '1994',
      material: 'Cast Bronze',
      dimensions: '6 ¹⁄₂" x 9 ¹⁄₂" x 18" [HxWxD] (16.51 x 24.13 x 45.72 cm)',
      images: {
         image_1: artwork_63_1,
         image_2: artwork_63_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'The Nature of the Beast I',
      year: '1994',
      material: 'Cast Bronze',
      dimensions: '13" x 8" x 10" [HxWxD] (33.02 x 20.32 x 25.4 cm)',
      images: {
         image_1: artwork_89_1,
         image_2: artwork_89_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'This Land is Our Land / Corporate Miner\'s Mind',
      year: '1994',
      material: 'Cast Bronze',
      dimensions: '6 ³⁄₄" x 9 ¹⁄₂" x 18" [HxWxD] (17.15 x 24.13 x 45.72 cm)',
      images: {
         image_1: artwork_112_1,
         image_2: artwork_112_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Videoanthropus Recumbens',
      year: '1995',
      material: 'Cast Bronze',
      dimensions: '10 ¹⁄₂" x 10" x 18" [HxWxD] (26.67 x 25.4 x 45.72 cm)',
      images: {
         image_1: artwork_118_1,
         image_2: artwork_118_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'American Crocodile - Fashion Sacrifice',
      year: '1994',
      material: 'Cast Bronze',
      dimensions: '14" x 9" x 16 ³⁄₄" [HxWxD] (35.56 x 22.86 x 42.55 cm)',
      images: {
         image_1: artwork_60_1,
         image_2: artwork_60_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Famine - Malthus was Right',
      year: '1995',
      material: 'Cast Bronze',
      dimensions: '17" x 7" x 7 ¹⁄₂" [HxWxD] (43.18 x 17.78 x 19.05 cm)',
      images: {
         image_1: artwork_79_1,
         image_2: artwork_79_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Overpopulation - The Mother Cannot Bear So Many',
      year: '1995',
      material: 'Cast Bronze',
      dimensions: '10" x 10" x 15" [HxWxD] (25.4 x 25.4 x 38.1 cm)',
      images: {
         image_1: artwork_100_1,
         image_2: artwork_100_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Automotive-Age Man - Self Sacrifice II',
      year: '1997',
      material: 'Cas',
      dimensions: '15 ¹⁄₂" x 6" x 10" [HxWxD] (39.37 x 15.24 x 25.4 cm)',
      images: {
         image_1: artwork_62_1,
         image_2: artwork_62_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Overpopulation - The Crux of the Matter II',
      year: '1995',
      material: 'Cast Bronze',
      dimensions: '21 ³⁄₄" x 8" x 19 ¹⁄₄" [HxWxD] (55.25 x 20.32 x 48.9 cm)',
      images: {
         image_1: artwork_98_1,
         image_2: artwork_98_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Overpopulation - The Crux of the Matter III',
      year: '1995',
      material: 'Cast Bronze',
      dimensions: '22 ¹⁄₂" x 10 ¹⁄₄" x 15 ¹⁄₄" [HxWxD] (57.15 x 26.04 x 38.74 cm)',
      images: {
         image_1: artwork_99_1,
         image_2: artwork_99_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Overpopulation - The Crux of the Matter I',
      year: '1995',
      material: 'Cast Bronze',
      dimensions: '23 ¹⁄₂" x 9" x 18" [HxWxD] (59.69 x 22.86 x 45.72 cm)',
      images: {
         image_1: artwork_97_1,
         image_2: artwork_97_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Aeronautic-age Man',
      year: '1995',
      material: 'Cast Bronze',
      dimensions: '13" x 7" x 14" [HxWxD] (33.02 x 17.78 x 35.56 cm)',
      images: {
         image_1: artwork_59_1,
         image_2: artwork_59_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'The Nature of the Beast II',
      year: '1995',
      material: 'Cast Bronze',
      dimensions: '15 ¹⁄₄" x 9" x 12" [HxWxD] (38.74 x 22.86 x 30.48 cm)',
      images: {
         image_1: artwork_90_1,
         image_2: artwork_90_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Onward Christian Soldiers',
      year: '1995',
      material: 'Cast Bronze',
      dimensions: '26 ¹⁄₂" x 11" x 12" [HxWxD] (67.31 x 27.94 x 30.48 cm)',
      images: {
         image_1: artwork_96_1,
         image_2: artwork_96_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Nuclear War Head',
      year: '1995',
      material: 'Cast Bronze',
      dimensions: '26 ¹⁄₂" x 11" x 12" [HxWxD] (67.31 x 27.94 x 30.48 cm)',
      images: {
         image_1: artwork_95_1,
         image_2: artwork_95_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Ultimate Consumer',
      year: '1996',
      material: 'Cast Bronze',
      dimensions: '9 ¹⁄₂" x 9 ¹⁄₄" x 15 ¹⁄₄" [HxWxD] (24.13 x 23.5 x 38.74 cm)',
      images: {
         image_1: artwork_117_1,
         image_2: artwork_117_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Famine II - The Children Go First',
      year: '1996',
      material: 'Cast Bronze',
      dimensions: '10" x 9 ¹⁄₂" x 10" [HxWxD] (25.4 x 24.13 x 25.4 cm)',
      images: {
         image_1: artwork_77_1,
         image_2: artwork_77_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Which Came Last?',
      year: '1996',
      material: 'Cast Bronze',
      dimensions: '7 ¹⁄₂" x 7" x 15 ¹⁄₂" [HxWxD] (19.05 x 17.78 x 39.37 cm)',
      images: {
         image_1: artwork_119_1,
         image_2: artwork_119_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Two Birds of Paradise Lost',
      year: '1996',
      material: 'Cast Bronze',
      dimensions: '25 ¹⁄₂" x 9 ¹⁄₂" x 7" [HxWxD] (64.77 x 24.13 x 17.78 cm)',
      images: {
         image_1: artwork_116_1,
         image_2: artwork_116_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Three Birds of Paradise Lost',
      year: '1995',
      material: 'Cast Bronze',
      dimensions: '14" x 13" x 13" [HxWxD] (35.56 x 33.02 x 33.02 cm)',
      images: {
         image_1: artwork_113_1,
         image_2: artwork_113_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Transglobal Virus I (Mickey Mouse)',
      year: '1997',
      material: 'Cast Bronze',
      dimensions: '14" x 11 ¹⁄₂" x 6 ³⁄₄" [HxWxD] (35.56 x 29.21 x 17.15 cm)',
      images: {
         image_1: artwork_114_1,
         image_2: artwork_114_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Transglobal Virus II',
      year: '1997',
      material: 'Cast Bronze',
      dimensions: '16 ¹⁄₄" x 8 ¹⁄₄" x 9 ¹⁄₂" [HxWxD] (41.28 x 20.96 x 24.13 cm)',
      images: {
         image_1: artwork_115_1,
         image_2: artwork_115_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'King Ludd - The Dark Side of Technology',
      year: '1996',
      material: 'Cast Bronze',
      dimensions: '8 ³⁄₄" x 12 ¹⁄₄" x 18 ¹⁄₂" [HxWxD] (22.23 x 31.12 x 46.99 cm)',
      images: {
         image_1: artwork_88_1,
         image_2: artwork_88_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Stop',
      year: '1996',
      material: 'Cast Bronze',
      dimensions: '15" x 5" x 10" [HxWxD] (38.1 x 12.7 x 25.4 cm)',
      images: {
         image_1: artwork_110_1,
         image_2: artwork_110_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Famine III',
      year: '1996',
      material: 'Cast Bronze',
      dimensions: '8 ³⁄₄" x 8" x 16 ¹⁄₂" [HxWxD] (22.23 x 20.32 x 41.91 cm)',
      images: {
         image_1: artwork_78_1,
         image_2: artwork_78_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Dr. Frankenkloner',
      year: '1997',
      material: 'Cast Bronze',
      dimensions: '19" x 13 ¹⁄₂" x 10 ¹⁄₂" [HxWxD] (48.26 x 34.29 x 26.67 cm)',
      images: {
         image_1: artwork_74_1,
         image_2: artwork_74_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Frigatebird',
      year: '1997',
      material: 'Cast Bronze',
      dimensions: '15 ¹⁄₂" x 6" x 10" [HxWxD] (39.37 x 15.24 x 25.4 cm)',
      images: {
         image_1: artwork_83_1,
         image_2: artwork_83_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Absolut Ron',
      year: '1998',
      material: 'Cast Bronze',
      dimensions: '10" x 3 ³⁄₈" x 3 ³⁄₈" [HxWxD] (25.4 x 8.59 x 8.59 cm)',
      images: {
         image_1: artwork_58_1,
         image_2: artwork_58_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'California Condor',
      year: '1993',
      material: 'Cast Bronze',
      dimensions: '9 ¹⁄₂" x 6" x 14 ¹⁄₂" [HxWxD] (24.13 x 15.24 x 36.83 cm)',
      images: {
         image_1: artwork_70_1,
         image_2: artwork_70_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Rising Waters I',
      year: '1997',
      material: 'Cast Bronze',
      dimensions: '7 ¹⁄₂" x 10" x 12" [HxWxD] (19.05 x 25.4 x 30.48 cm)',
      images: {
         image_1: artwork_104_1,
         image_2: artwork_104_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Rising Waters II',
      year: '1998',
      material: 'Cast Bronze',
      dimensions: '10" x 7 ¹⁄₂" x 11" [HxWxD] (25.4 x 19.05 x 27.94 cm)',
      images: {
         image_1: artwork_105_1,
         image_2: artwork_105_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Dick\'s World',
      year: '1999',
      material: 'Cast Bronze',
      dimensions: '11 ¹⁄₄" x 9" x 9" [HxWxD] (28.58 x 22.86 x 22.86 cm)',
      images: {
         image_1: artwork_72_1,
         image_2: artwork_72_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Yes Sir, That\'s My Baby',
      year: '1998',
      material: 'Cast Bronze',
      dimensions: '12" x 7 ³⁄₄" x 6" [HxWxD] (30.48 x 19.69 x 15.24 cm)',
      images: {
         image_1: artwork_122_1,
         image_2: artwork_122_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'No Sir, I Don\'t Mean Maybe',
      year: '1999',
      material: 'Cast Bronze',
      dimensions: '25" x 11 ¹⁄₂" x 13" [HxWxD] (63.5 x 29.21 x 33.02 cm)',
      images: {
         image_1: artwork_93_1,
         image_2: artwork_93_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Gone Fishin',
      year: '1998',
      material: 'Cast Bronze',
      dimensions: '25" x 11 ¹⁄₂" x 13" [HxWxD] (63.5 x 29.21 x 33.02 cm)',
      images: {
         image_1: artwork_84_1,
         image_2: artwork_84_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'End of the Trail, Homeless',
      year: '1999',
      material: 'Cast Bronze',
      dimensions: '18 ¹⁄₂" x 10 ¹⁄₄" x 18" [HxWxD] (46.99 x 26.04 x 45.72 cm)',
      images: {
         image_1: artwork_76_1,
         image_2: artwork_76_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Platypus - Vanishing Freak',
      year: '1999',
      material: 'Cast Bronze',
      dimensions: '20 ¹⁄₂" x 6" x 6 ¹⁄₂" [HxWxD] (52.07 x 15.24 x 16.51 cm)',
      images: {
         image_1: artwork_101_1,
         image_2: artwork_101_2,
         alt: 'anthropocene skulls'
      }
   },
   {
      category: 'anthropocene-skulls',
      name: 'Yab Yum, Thank You Mum',
      year: '1999',
      material: 'Cast Bronze',
      dimensions: '19" x 7" x 9" [HxWxD] (48.26 x 17.78 x 22.86 cm)',
      images: {
         image_1: artwork_121_1,
         image_2: artwork_121_2,
         alt: 'anthropocene skulls'
      }
   },

   {
      category: 'sculptures',
      name: 'Unknown 4',
      year: '',
      material: '',
      dimensions: '12" x 5" x 6" [HxWxD] (30.48 x 12.7 x 15.24 cm)',
      images: {
         image_1: artwork_51_1,
         image_2: artwork_51_2,
         alt: 'sculpture'
      }
   },
   {
      category: 'sculptures',
      name: 'Unknown 3',
      year: '',
      material: '',
      dimensions: '12" x 5" x 6" [HxWxD] (30.48 x 12.7 x 15.24 cm)',
      images: {
         image_1: artwork_52_1,
         image_2: artwork_52_2,
         alt: 'sculpture'
      }
   },
   {
      category: 'sculptures',
      name: 'Unknown 6',
      year: '',
      material: '',
      dimensions: '7" x 3" x 3" [HxWxD] (17.78 x 7.62 x 7.62 cm)',
      images: {
         image_1: artwork_55_1,
         image_2: artwork_55_2,
         alt: 'sculpture'
      }
   },
   {
      category: 'sculptures',
      name: 'Unknown 7',
      year: '',
      material: '',
      dimensions: '7" x 3" x 3" [HxWxD] (17.78 x 7.62 x 7.62 cm)',
      images: {
         image_1: artwork_56_1,
         image_2: artwork_56_2,
         alt: 'sculpture'
      }
   },
   {
      category: 'sculptures',
      name: 'Bird Goddess',
      year: '1991',
      material: 'Raku',
      dimensions: '10 ¹⁄₂" x 4" x 1 ³⁄₄" [HxWxD] (26.67 x 10.16 x 4.45 cm)',
      images: {
         image_1: artwork_57_1,
         image_2: artwork_57_2,
         alt: 'sculpture'
      }
   },
   {
      category: 'sculptures',
      name: 'Bone Mother Goddess',
      year: '1988',
      material: 'Jawbone of Ass',
      dimensions: '14 ¹⁄₂" [H] (36.83 cm)',
      images: {
         image_1: artwork_68_1,
         image_2: artwork_68_2,
         alt: 'sculpture'
      }
   },
   {
      category: 'sculptures',
      name: 'Frau Willendorf',
      year: '1990',
      material: 'Cast Bronze',
      dimensions: '62" x 14" x 14" [HxWxD] (157.48 x 35.56 x 35.56 cm)',
      images: {
         image_1: artwork_82_1,
         image_2: artwork_82_2,
         alt: 'sculpture'
      }
   },
   {
      category: 'sculptures',
      name: 'Nike',
      year: '1963',
      material: 'Cast Bronze',
      dimensions: '80" [H] (203.2 cm)',
      images: {
         image_1: artwork_91_1,
         image_2: artwork_91_2,
         alt: 'sculpture'
      }
   },
   {
      category: 'sculptures',
      name: 'Nike II',
      year: '1964',
      material: 'Cast Bronze',
      dimensions: '12" x 24" [HxW] (30.48 x 60.96 cm)',
      images: {
         image_1: artwork_92_1,
         image_2: artwork_92_2,
         alt: 'sculpture'
      }
   },
   
   {
      category: 'pastels',
      name: 'Unknown',
      year: '2005',
      material: 'Charcoal on paper',
      dimensions: '20 ¹⁄₂" x 16 ¹⁄₂" [HxW] (52.07 x 41.91 cm)',
      images: {
         image_1: artwork_123_1,
         image_2: artwork_123_2,
         alt: 'pastel'
      }
   },
   {
      category: 'pastels',
      name: 'Unknown',
      year: '2008',
      material: 'Charcoal on paper',
      dimensions: '25" x 19" [HxW] (63.5 x 48.26 cm)',
      images: {
         image_1: artwork_124_1,
         image_2: artwork_124_2,
         alt: 'pastel'
      }
   },
   {
      category: 'pastels',
      name: 'Pyramids of Giza [The Great Pyramids of Giza]',
      year: '2018',
      material: 'Charcoal and oil pastel on paper',
      dimensions: '',
      images: {
         image_1: artwork_125_1,
         image_2: artwork_125_2,
         alt: 'pastel'
      }
   },
   {
      category: 'pastels',
      name: 'Shasta 3',
      year: '2005',
      material: 'Charcoal on paper',
      dimensions: '24" x 18" [HxW] (60.96 x 45.72 cm)',
      images: {
         image_1: artwork_126_1,
         image_2: artwork_126_2,
         alt: 'pastel'
      }
   },
   {
      category: 'pastels',
      name: 'Shasta 4',
      year: '2005',
      material: 'Charcoal on paper',
      dimensions: '24" x 18" [HxW] (60.96 x 45.72 cm)',
      images: {
         image_1: artwork_127_1,
         image_2: artwork_127_2,
         alt: 'pastel'
      }
   },
   {
      category: 'pastels',
      name: 'Shasta 5',
      year: '2005',
      material: 'Charcoal on paper',
      dimensions: '18" x 24" [HxW] (45.72 x 60.96 cm)',
      images: {
         image_1: artwork_128_1,
         image_2: artwork_128_2,
         alt: 'pastel'
      }
   },
   {
      category: 'pastels',
      name: 'Shasta 7',
      year: '2006',
      material: 'Charcoal on paper',
      dimensions: '18" x 24" [HxW] (45.72 x 60.96 cm)',
      images: {
         image_1: artwork_129_1,
         image_2: artwork_129_2,
         alt: 'pastel'
      }
   },
   {
      category: 'pastels',
      name: 'Shasta 9',
      year: '2006',
      material: 'Charcoal on paper',
      dimensions: '10" x 8" [HxW] (25.4 x 20.32 cm)',
      images: {
         image_1: artwork_130_1,
         image_2: artwork_130_2,
         alt: 'pastel'
      }
   },
   {
      category: 'pastels',
      name: 'Shasta 18',
      year: '2007',
      material: 'Charcoal and Chalk on Paper',
      dimensions: '24" x 18" [HxW] (60.96 x 45.72 cm)',
      images: {
         image_1: artwork_131_1,
         image_2: artwork_131_2,
         alt: 'pastel'
      }
   },
   {
      category: 'pastels',
      name: 'Shasta 19',
      year: '2007',
      material: 'Charcoal and Chalk on Paper',
      dimensions: '24" x 18" [HxW] (60.96 x 45.72 cm)',
      images: {
         image_1: artwork_132_1,
         image_2: artwork_132_2,
         alt: 'pastel'
      }
   },
   {
      category: 'pastels',
      name: 'Shasta 20',
      year: '2007',
      material: 'Charcoal and Chalk on Paper',
      dimensions: '24" x 18" [HxW] (60.96 x 45.72 cm)',
      images: {
         image_1: artwork_133_1,
         image_2: artwork_133_2,
         alt: 'pastel'
      }
   },
   {
      category: 'pastels',
      name: 'Shasta 21',
      year: '2007',
      material: 'Charcoal and Chalk on Paper',
      dimensions: '18" x 24" [HxW] (45.72 x 60.96 cm)',
      images: {
         image_1: artwork_134_1,
         image_2: artwork_134_2,
         alt: 'pastel'
      }
   },
   {
      category: 'pastels',
      name: 'Shasta 34',
      year: '2007',
      material: 'Charcoal on paper',
      dimensions: '12" x 20" [HxW] (30.48 x 50.8 cm)',
      images: {
         image_1: artwork_135_1,
         image_2: artwork_135_2,
         alt: 'pastel'
      }
   },

   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 1',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '28" x 21" [HxW] (71.12 x 53.34 cm)',
      images: {
         image_1: artwork_136_1,
         image_2: artwork_136_2,
         alt: 'sumi ink on paper and silk'
      }
   },

   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 2',
      year: '2010',
      material: 'Sumi ink on handmade paper, mounted on Chinese silk to board',
      dimensions: '16" x 15" [HxW] (40.64 x 38.1 cm)',
      images: {
         image_1: artwork_137_1,
         image_2: artwork_137_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 3',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '25" x 18" [HxW] (63.5 x 45.72 cm)',
      images: {
         image_1: artwork_138_1,
         image_2: artwork_138_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 4',
      year: '2010',
      material: 'Sumi ink on handmade paper, mounted on Chinese silk to board',
      dimensions: '17" x 14" [HxW] (43.18 x 35.56 cm)',
      images: {
         image_1: artwork_139_1,
         image_2: artwork_139_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 5',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '22" x 20" [HxW] (55.88 x 50.8 cm)',
      images: {
         image_1: artwork_140_1,
         image_2: artwork_140_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 6',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '23" x 16" [HxW] (58.42 x 40.64 cm)',
      images: {
         image_1: artwork_141_1,
         image_2: artwork_141_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 7',
      year: '2010',
      material: 'Sumi ink on handmade paper, mounted on Chinese silk to board',
      dimensions: '18" x 17" [HxW] (45.72 x 43.18 cm)',
      images: {
         image_1: artwork_142_1,
         image_2: artwork_142_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 8',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '18" x 17" [HxW] (45.72 x 43.18 cm)',
      images: {
         image_1: artwork_143_1,
         image_2: artwork_143_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 9',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '24" x 15" [HxW] (60.96 x 38.1 cm)',
      images: {
         image_1: artwork_144_1,
         image_2: artwork_144_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 10',
      year: '2010',
      material: 'Sumi ink on handmade paper, mounted on Chinese silk to board',
      dimensions: '25" x 26" [HxW] (63.5 x 66.04 cm)',
      images: {
         image_1: artwork_145_1,
         image_2: artwork_145_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 11',
      year: '2010',
      material: 'Sumi ink on handmade paper, mounted on Chinese silk to board',
      dimensions: '26" x 17" [HxW] (66.04 x 43.18 cm)',
      images: {
         image_1: artwork_146_1,
         image_2: artwork_146_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 12',
      year: '2010',
      material: 'Sumi ink on handmade paper, mounted on Chinese silk to board',
      dimensions: '26" x 17" [HxW] (66.04 x 43.18 cm)',
      images: {
         image_1: artwork_147_1,
         image_2: artwork_147_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 13',
      year: '2010',
      material: 'Sumi ink on handmade paper, mounted on Chinese silk to board',
      dimensions: '28" x 23" [HxW] (71.12 x 58.42 cm)',
      images: {
         image_1: artwork_148_1,
         image_2: artwork_148_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 14',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '24" x 20" [HxW] (60.96 x 50.8 cm)',
      images: {
         image_1: artwork_149_1,
         image_2: artwork_149_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 16',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '43" x 18" [HxW] (109.22 x 45.72 cm)',
      images: {
         image_1: artwork_150_1,
         image_2: artwork_150_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 17',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '20" x 17" [HxW] (50.8 x 43.18 cm)',
      images: {
         image_1: artwork_151_1,
         image_2: artwork_151_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 18',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '37" x 15" [HxW] (93.98 x 38.1 cm)',
      images: {
         image_1: artwork_152_1,
         image_2: artwork_152_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 19',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '37" x 15" [HxW] (93.98 x 38.1 cm)',
      images: {
         image_1: artwork_153_1,
         image_2: artwork_153_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 20',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '36" x 15" [HxW] (91.44 x 38.1 cm)',
      images: {
         image_1: artwork_154_1,
         image_2: artwork_154_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 21',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '44" x 14" [HxW] (111.76 x 35.56 cm)',
      images: {
         image_1: artwork_155_1,
         image_2: artwork_155_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 22',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '31" x 15" [HxW] (78.74 x 38.1 cm)',
      images: {
         image_1: artwork_156_1,
         image_2: artwork_156_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 24',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '48" x 15" [HxW] (121.92 x 38.1 cm)',
      images: {
         image_1: artwork_157_1,
         image_2: artwork_157_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 25',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '42" x 15" [HxW] (106.68 x 38.1 cm)',
      images: {
         image_1: artwork_158_1,
         image_2: artwork_158_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 26',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '42" x 15" [HxW] (106.68 x 38.1 cm)',
      images: {
         image_1: artwork_159_1,
         image_2: artwork_159_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 27',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '24" x 16" [HxW] (60.96 x 40.64 cm)',
      images: {
         image_1: artwork_160_1,
         image_2: artwork_160_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 28',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '54" x 16" [HxW] (137.16 x 40.64 cm)',
      images: {
         image_1: artwork_161_1,
         image_2: artwork_161_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 29',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '43" x 15" [HxW] (109.22 x 38.1 cm)',
      images: {
         image_1: artwork_162_1,
         image_2: artwork_162_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 30',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '41" x 15" [HxW] (104.14 x 38.1 cm)',
      images: {
         image_1: artwork_163_1,
         image_2: artwork_163_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 31',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '42" x 15" [HxW] (106.68 x 38.1 cm)',
      images: {
         image_1: artwork_164_1,
         image_2: artwork_164_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 32',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '42" x 15" [HxW] (106.68 x 38.1 cm)',
      images: {
         image_1: artwork_165_1,
         image_2: artwork_165_2,
         alt: 'sumi ink on paper and silk'
      }
   },
   {
      category: 'sumi-ink-on-paper-and-silk',
      name: 'Eight Billion 33',
      year: '2010',
      material: 'Sumi ink on hand made paper mounted on Chinese silk scroll',
      dimensions: '43" x 16" [HxW] (109.22 x 40.64 cm)',
      images: {
         image_1: artwork_166_1,
         image_2: artwork_166_2,
         alt: 'sumi ink on paper and silk'
      }
   }, 
   
   {
      category: 'drawings',
      name: 'Al Quaida (911-2001 Osama) [Faces of War]',
      year: '2013',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_167_1,
         image_2: artwork_167_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Trump Unbalanced [Faces of War]',
      year: '2018',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_168_1,
         image_2: artwork_168_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Vietnam / Mortar shot [Vietnam]',
      year: '2013',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_169_1,
         image_2: artwork_169_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Vietnam / Agent Orange [Vietnam]',
      year: '2013',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_170_1,
         image_2: artwork_170_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Vietnam / Assault After Dark [Vietnam]',
      year: '2013',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_171_1,
         image_2: artwork_171_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Vietnam / Cemetary of a lost war [Vietnam]',
      year: '2013',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_172_1,
         image_2: artwork_172_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Vietnam / Mylai Massacre [Vietnam]',
      year: '2013',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_173_1,
         image_2: artwork_173_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Vietnam / Gunsnip Down [Vietnam]',
      year: '2013',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_174_1,
         image_2: artwork_174_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War II (Japan, Pearl Harbor, Dec. 7, 1941) [Faces of War]',
      year: '2019',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_175_1,
         image_2: artwork_175_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War I / German 1914 [Faces of War]',
      year: '2012',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_176_1,
         image_2: artwork_176_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War I / The Unknown Soldier 1915 [Faces of War]',
      year: '2012',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_177_1,
         image_2: artwork_177_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War I / French 1915 [Faces of War]',
      year: '2012',
      material: 'Colored marker on gold rimmed board',
      dimensions: '',
      images: {
         image_1: artwork_178_1,
         image_2: artwork_178_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War I / Austrian 1915 [Faces of War]',
      year: '2012',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_179_1,
         image_2: artwork_179_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War I / British 1915 [Faces of War]',
      year: '2012',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_180_1,
         image_2: artwork_180_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War I / Mustard Gas British 1916 [Faces of War]',
      year: '1916',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_181_1,
         image_2: artwork_181_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War I / German/British 1916 [Faces of War]',
      year: '2012',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_182_1,
         image_2: artwork_182_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War II / IL Duce Italy [Faces of War]',
      year: '2013',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_183_1,
         image_2: artwork_183_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War II / British-USA / Dresdeń-Hamburg / Munich-Civilians [Faces of War]',
      year: '2013',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_184_1,
         image_2: artwork_184_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War II 1944 / Auschwitz Germany [Faces of War]',
      year: '2013',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_185_1,
         image_2: artwork_185_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War II / Hiroshima 1945 [Faces of War]',
      year: '2013',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_186_1,
         image_2: artwork_186_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War II / Japan Emporer Hiroshito [Faces of War]',
      year: '2013',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_187_1,
         image_2: artwork_187_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War II / German 1933 [Faces of War]',
      year: '2013',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_188_1,
         image_2: artwork_188_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War I / British 1917 [Faces of War]',
      year: '2012',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_189_1,
         image_2: artwork_189_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War II / The Good War [Faces of War]',
      year: '2013',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_190_1,
         image_2: artwork_190_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'World War II 1942 / Russian Double Cross [Faces of War]',
      year: '2013',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_191_1,
         image_2: artwork_191_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Romans vs Christians / CEO Board of Directors St. Peter, the Rock [Romans vs Christians]',
      year: '2011',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_192_1,
         image_2: artwork_192_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Romans vs Christians / Founder of the Vatican Bank / 1st Martry to the Tax Free Religious Corporation Jesus I AD [Romans vs Christians]',
      year: '2011',
      material: 'Colored marker on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_193_1,
         image_2: artwork_193_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Romans vs Christians / St. Larry of Rome 258 AD Martyred by barbecue [Romans vs Christians]',
      year: '2003',
      material: 'Ink and gold paint with pastel on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_194_1,
         image_2: artwork_194_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Romans vs Christians / St. Mathew Stoned and Beheaded',
      year: '2011',
      material: 'Marker with metallic ink on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_195_1,
         image_2: artwork_195_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Romans vs. Christians / Director of Communications St. Matthew Evangelist Beheaded [Romans vs Christians]',
      year: '2011',
      material: 'Marker with metallic ink on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_196_1,
         image_2: artwork_196_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Romans vs Christians / St. John Evangelist Beheaded [Romans vs Christians]',
      year: '2011',
      material: 'Black marker with metallic ink on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_197_1,
         image_2: artwork_197_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Romans vs Christians / Board of Directors St. James the Great, Beheaded [Romans vs Christians]',
      year: '2011',
      material: 'Marker with metallic ink on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_198_1,
         image_2: artwork_198_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Romans vs Christians / Doubting Thomas, Apostle Speared [Romans vs Christians]',
      year: '2013',
      material: 'Marker with metallic ink on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_199_1,
         image_2: artwork_199_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Romans vs Christians / St. Mark, Evangelist Beheaded [Romans vs Christians]',
      year: '2011',
      material: 'Marker with metallic ink on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_200_1,
         image_2: artwork_200_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Romans vs Christians / Communication Dept. St. Luke Hanged [Romans vs Christians]',
      year: '2011',
      material: 'Marker with metallic ink on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_201_1,
         image_2: artwork_201_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Romans vs Christians / St. Andrew Brother of Peter Crucified [Romans vs Christians]',
      year: '2011',
      material: 'Marker with metallic ink on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_202_1,
         image_2: artwork_202_2,
         alt: 'drawing'
      }
   },
   {
      category: 'drawings',
      name: 'Romans vs Christians / Venture Capitalist St. Sebastian Slain by Arrows [Romans vs Christians]',
      year: '2011',
      material: 'Marker with metallic ink on gold rimmed board',
      dimensions: '10 ¹⁄₄" x 9" [HxW] (26.04 x 22.86 cm)',
      images: {
         image_1: artwork_203_1,
         image_2: artwork_203_2,
         alt: 'drawing'
      }
   },
];

export const bio = [
   <p>
      Ron Garrigues (b. 1930, San Francisco, CA - d. 2020, Bolinas, CA) was a pioneering artist of the Anthropocene movement and was one of the first fine artists to create work thematically focused on climate change, animal extinction, and social inequity. He is most recognized for his iconic bronze skulls begun in 1989 following the Exxon Valdez oil spill catastrophe. A self-taught artist influenced by Constantin Brancusi, Isamu Noguchi, and working closely with San Francisco-based sculptor Peter Voulkos, he began sculpting in the late 1950s in an old barn on the island farm of Charles Foster. His first body of works were wooden sculptures of lyrical line and beauty all made with hand tools. Through meeting Peter Voulkos, Garrigues began working with bronze and lead, casting in the lost wax process.
   </p>,
   <p>
      Garrigues is an early artist of the Anthropocene Movement which looks at the intersection of humankind and the environment. The core of Garrigues’ message is that our disrespect for the forces of nature will lead to disaster. He weaves this stark idea in with subtlety and dark humor, provoking the viewer to examine their own relationship with nature. “When I first started doing this, it was to affect people’s consciousness about what was going on in the world. I thought it was an indignity to the planet, what was being done for profit,” he says. “Mankind is the greatest predator that ever lived on earth. If humans were to continue doing what we’re doing, there’d be nothing left.” His Anthropocenic work has been the subject of numerous one-person and retrospective exhibitions, including at Canessa Gallery (2021), the Bolinas Museum (2007 and 2000), Varnish Fine Arts (2005 and 2003), the Sonoma Museum of Visual Arts (1999), and the Gorbachev State of the World Forum(1995, 1996, and 1997). His work is featured in the Crocker Art Museum, the Hood Museum of Art at Dartmouth University, and private collections.
   </p>
];

export const exhibitionsItems = [
   {
      title: 'POSTHUMOUS EXHIBITIONS AND AUCTIONS',
      description: [
         '2021 AAG Auctioneers, Amsterdam, The Netherlands',
         '2021 Canessa Gallery, San Francisco, CA',
      ]
   },

   {
      title: 'SOLO EXHIBITIONS',
      description: [
         '2007 Bolinas Museum, Bolinas, CA',
         '2006 “Y” Fest, San Francisco, CA',
         '2005 Varnish Fine Arts, San Francisco, CA',
         '2004 Fetterly Gallery, Vallejo, CA',
         '2004 Commonweal Gallery, Bolinas, CA',
         '2003 Varnish Fine Arts, San Francisco, CA',
         '2003 Roshambo Gallery, Healdsburg, CA',
         '2001 111 Minna Gallery, San Francisco, CA',
         '2000 Bolinas Museum, Bolinas, CA',
         '2000 Mussi Artworks Gallery, Berkeley, CA',
         '1999 Sonoma Museum of Visual Arts, Santa Rosa, CA',
         '1999 Stewart-Kummer Gallery, Gualala, CA',
         '1999 111 Minna Gallery, San Francisco, CA',
         '1998 Michael Himovitz Gallery, Sacramento, CA',
         '1997 Gorbachev State of the World Forum, San Francisco, CA',
         '1996 Gorbachev State of the World Forum, San Francisco, CA',
         '1995 Gorbachev State of the World Forum, San Francisco, CA',
         '1965 Hollis Gallery, San Francisco, CA',
         '1963 Stable Gallery, New York, NY',
         '1963 Pasadena Art Museum, Pasadena, CA',
         '1963 Santa Barbara Museum of Art, Santa Barbara, CA',
         '1961 California Palace of the Legion of Honor, San Francisco, CA',
      ]
   },

   {
      title: 'GROUP EXHIBITIONS',
      description: [
         '2011 Scope Art Miami (Varnish Fine Arts), Miami, FL',
         '2011 Bolinas Museum, Bolinas, CA',
         '2011 Varnish Fine Art Inaugural Opening, San Francisco, CA',
         '2010 Robert Berman Gallery, San Francisco, CA',
         '2009 Bolinas Museum, Bolinas, CA',
         '2008 Stewart-Kummer Gallery, Gualala, CA',
         '2008 Bolinas Museum, Bolinas, CA',
         '2007 Stewart-Kummer Gallery, Gualala, CA',
         '2006 Varnish Fine Arts, San Francisco, CA',
         '2006 Stewart-Kummer Gallery, Gualala, CA',
         '2006 Bolinas Museum, Bolinas, CA',
         '2005 Solomon-Dubnick Gallery, Sacramento, CA',
         '2005 Miniature Show, Annual, Bolinas, CA',
         '2005 Bolinas Museum Annual Exhibit, Bolinas, CA',
         '2005 Varnish Fine Arts, San Francisco, CA',
         '2005 Claudia Chaplin Gallery, Stinson Beach, CA',
         '2005 Robert Berman Gallery, Santa Monica, CA',
         '2005 Japanese American Historical Society',
         '“60th Commemoration Hiroshima”, San Francisco, CA',
         '2005 California Lawyers for the Arts, Sacramento, CA',
         '2004 Commonweal Gallery, CA',
         '2004 Robert Berman Gallery, Santa Monica, CA',
         '2004 Stewart-Kummer Gallery, Gualala, CA',
         '2004 Varnish Fine Arts, San Francisco, CA',
         '2004 Bolinas Museum, Bolinas, CA',
         '2003 111 Minna Street Gallery, San Francisco, CA',
         '2003 Varnish Fine Arts, San Francisco, CA',
         '2003 Dovetail Gallery, Chico, CA',
         '2003 S.F. Intn’l Art Expo, R. Berman Gallery, Ft. Mason, San Francisco, CA',
         '2002 Robert Berman Gallery, Santa Monica, CA',
         '2002 Claudia Chapeline Gallery, Stinson Beach, CA',
         '2002 ADIDA’S ART SHOE World Tour, USA, Asia, Europe',
         '2002 EAMES ART CHAIR World Tour, USA, Asia, Europe',
         '2002 Steward-Kummer Gallery, Gualala, CA',
         '2002 S.F. Intn’l Art Expo, R. Berman Gallery, Ft. Mason, San Francisco, CA',
         '2001 Robert Berman Gallery, Santa Monica, CA',
         '2001 Stewart-Kummer Gallery, Gualala, CA',
         '2001 111 Minna Gallery, San Francisco, CA, 7-11 Group Show',
         '2000 Stewart-Kummer Gallery, Gualala, CA',
         '1999 Art in the Redwoods, Gualala, CA',
         '1998 Center for Art and Earth, New York',
         '1997 Center for Art and Earth, New York',
         '1961 San Francisco Museum of Art, Twenty Fourth Annual Drawing, Print and Sculpture Exhibition',
         '1960 Eric Locke Galleries, San Francisco, Third West Coast Sculpture Show',
         '1960 San Francisco, Fourteenth Art Festival',
         '1959 Oakland Art Museum, California Sculptors’ Annual Exhibition',
      ]
   },
];