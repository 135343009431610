import Exhibitions from "../components/Exhibitions";
import Container from "../components/Container";
import Footer from "../components/Footer";
import Main from "../components/Main";

const ExhibitionsPage = () => {
   return ( 
      <>
         <Main>
            <Container>
               <Exhibitions />
            </Container>
         </Main>
         <Footer />
      </>
   );
}
 
export default ExhibitionsPage;