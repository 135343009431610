import { useParams } from "react-router-dom";
import Container from "../components/Container";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";
import Main from "../components/Main";
import NotFoundPage from "./NotFoundPage";
import { galleryItems, subMenuItems } from "../data";

const GalleryPage = () => {
   const {id} = useParams();
   const filteredGalleryItems = galleryItems.filter(item => item.category === id);
   let existsCategory = true;
   
   if (id) {
      existsCategory = subMenuItems.some(item => item.to === id);
   }

   if (!existsCategory) {
       return <NotFoundPage />
   } 

   return ( 
      <>
         <Main>
            <Container>
               <Gallery 
                  subMenuItems={subMenuItems}
                  galleryItems={filteredGalleryItems} 
               />
            </Container>
         </Main>
         <Footer />
      </>
   );
}
 
export default GalleryPage;