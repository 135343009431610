import { HiOutlineX, HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import styled from "styled-components";
import LoadableImage from "./LoadableImage";

const StyledMyLightbox = styled.div`
   position: fixed;
   top: 0;
   left: 0;
   z-index: 60;
   width: 100vw;
   height: 100vh;
   background-color: rgba(0, 0, 0, .85);
   visibility: hidden;
   opacity: 0;
   transition: visibility .5s, 
               opacity .5s;

   &.opened {
      visibility: visible; 
      opacity: 1;
   }

   .lightbox-close-icon {
      position: fixed;
      top: 63px;
      right: 63px;
      cursor: pointer;

      * {
         stroke-width: 1px;
      }
   }

   .lightbox-left-arrow-icon,
   .lightbox-right-arrow-icon {
      position: fixed;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;

      * {
         stroke-width: 1px;
      }     
   }

   .lightbox-left-arrow-icon {
      left: 63px;
   }

   .lightbox-right-arrow-icon {
      right: 63px;
   }

   .lightbox-item-image {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 50vw;
      height: 80vh;
      margin: auto;

      img {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         object-fit: contain;
         user-select: none;
      }
   }

   .lightbox-item-info {
      position: fixed;
      top: 63px;
      left: 63px;
      padding-bottom: 25px;
      max-width: 20vw;
      color: ${({theme}) => theme.colors.primary};
      font-weight: ${({theme}) => theme.fonts.weight};
      font-size: ${({theme}) => theme.fonts.size};
      line-height: ${({theme}) => theme.fonts.lineHeight};
      user-select: none;

      & > div:first-child {
         margin-bottom: 15px;
         font-weight: bold;
      }
   }
`;

const MyLightbox = ({items, isOpen, setIsOpen, currentItemIndex, setCurrentItemIndex, isClicked, setIsClicked, ...props}) => {
   const closeMyLightbox = () => {
      setIsOpen(false);
   };

   const goToPreviousItem = () => {
      setCurrentItemIndex(currentItemIndex - 1);
      setIsClicked(true);
   };

   const goToNextItem = () => {
      setCurrentItemIndex(currentItemIndex + 1);
      setIsClicked(true);
   };

   const currentItem = items.find((item, index) => index === currentItemIndex);

   return ( 
      <StyledMyLightbox 
         {...props} 
         className={isOpen ? 'opened' : ''}
      >
         <HiOutlineX 
            className="lightbox-close-icon"
            size="50px"
            color="#fff"
            onClick={closeMyLightbox} 
         />
         {currentItemIndex > 0
            ?
            <HiOutlineChevronLeft 
               className="lightbox-left-arrow-icon"
               size="50px"
               color="#fff"
               onClick={goToPreviousItem}
            />
            :
            null}
         <div className="lightbox-item">
            <LoadableImage
               className="lightbox-item-image" 
               src={currentItem?.images.image_2}
               alt={`${currentItem?.name}`}
               isClicked={isClicked}
            />
            <div className="lightbox-item-info">
               <div>{currentItem?.name}</div>
               <div>{currentItem?.year}</div>
               <div>{currentItem?.material}</div>
               <div>{currentItem?.dimensions}</div>
            </div>
         </div>
         {currentItemIndex < items.length - 1
            ?
            <HiOutlineChevronRight 
               className="lightbox-right-arrow-icon"
               size="50px"
               color="#fff"
               onClick={goToNextItem}
            />
            :
            null}
      </StyledMyLightbox>
   );
}
 
export default MyLightbox;