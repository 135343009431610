import { HiOutlineX } from "react-icons/hi";
import styled from "styled-components";
import MyNavLink from "./MyNavLink";

const StyledMyMenu = styled.nav`
   position: fixed;
   top: 0;
   left: 0;
   z-index: 50;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
   background-color: #000;
   transform: ${({isActive}) => 
      isActive 
      ? 
      'translate3d(0, 0, 0)' 
      : 
      'translate3d(100%, 0, 0)'};
   transition: transform .15s ease-in;

   .close-icon {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;

      * {
         stroke-width: 1px;
      }
   }

   li {
      text-align: center;
   }

   li:not(:last-child) {
      margin-bottom: 36px;
   }
`;

const MyMenu = ({items, setIsActive, ...props}) => {
   const closeMyMenu = () => {
      setIsActive(false);
      document.body.style.overflow = 'auto';
   };

   return ( 
      <StyledMyMenu {...props}>
         <HiOutlineX 
            className="close-icon"
            size="30px"
            color="#fff"
            onClick={closeMyMenu}
         />
         <ul>
            {items.map(item =>
               <li key={item.to}>
                  <MyNavLink 
                     className="my-menu-navlink" 
                     to={item.to}
                     onClick={closeMyMenu}
                  >
                     {item.content}
                  </MyNavLink>
               </li>)}
         </ul> 
      </StyledMyMenu>
   );
}
 
export default MyMenu;