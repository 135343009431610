import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import Loader from "./Loader";

const StyledLoadableImage2 = styled.div`
   width: 100%;
   height: 100%;

   picture {
      opacity: 0;
      transition: opacity .5s linear;

      &.loadedImage {
         opacity: 1;
      }
   }

   .loader-wrapper {
      position: fixed;
      z-index: 80;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: #000;
   }

   .loader-slider {     
      position: fixed;
      z-index: 90;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;   
   }
`;

const LoadableImage2 = ({src_1, src_2, alt}) => {
   const [isLoaded, setIsLoaded] = useState(false);
   const imageRef = useRef(null);

   useEffect(() => {
      if (imageRef.current) {
         imageRef.current.onload = () => setIsLoaded(true);
      }
   }, []);

   return ( 
      <StyledLoadableImage2>
         <picture
            className={isLoaded ? 'loadedImage' : ''}
         >
            <source srcSet={src_2} media="(min-width: 720px)" />
            <img src={src_1} alt={alt} ref={imageRef} />
         </picture>
         {!isLoaded
            && 
            <div className="loader-wrapper">
               <Loader className="loader-slider" /> 
            </div>}
      </StyledLoadableImage2>
   );
}
 
export default LoadableImage2;