import styled from "styled-components";
import { exhibitionsItems } from "../data";
import exhibitions from "../assets/coverpic.jpg";

const StyledExhibitions = styled.div`
   display: flex;
   flex-direction: column;
   color: ${({theme}) => theme.colors.primary};
   font-weight: ${({theme}) => theme.fonts.weight};

   @media ${({theme}) => theme.media.tablet} {
      flex-direction: row;
   }

   .exhibitions-image-wrapper {
      margin-bottom: 58px;

      @media ${({theme}) => theme.media.tablet} {
         align-self: flex-start;
         flex: 0 0 auto;
         width: 33.18%;
         margin-right: 40px;
         margin-bottom: 0;
      }

      @media ${({theme}) => theme.media.desktop} {
         margin-right: 78px;
      }
   }

   .exhibitions-info > div:not(:last-child) {
      margin-bottom: 58px;

      @media ${({theme}) => theme.media.tablet} {
         margin-bottom: 124px;
      }
   }

   img {
      display: block;
      width: 100%;
      height: 100%;
   }

   .exhibitions-title {
      margin-bottom: 15px;

      @media ${({theme}) => theme.media.desktop} {
         margin-bottom: 24px;
      }
   }

   .exhibitions-description {
      font-size: 14px;
      line-height: 27px;

      @media ${({theme}) => theme.media.desktop} {
         font-size: ${({theme}) => theme.fonts.size};
         line-height: 30px;
      }

      p {
         margin-bottom: 5px;
         line-height: ${({theme}) => theme.fonts.lineHeight};
      }
   }
`;

const Exhibitions = props => {
  return ( 
    <StyledExhibitions {...props}>
      <div className="exhibitions-image-wrapper">
        <img src={exhibitions} alt="exhibitions" />
      </div> 
      <div className="exhibitions-info">
        {exhibitionsItems.map(item => 
          <div key={item.title}>
            <h2 className="exhibitions-title">{item.title}</h2>
            <div className="exhibitions-description">
              {item.description.map((item, index) =>
                <p key={`${item.title} ${index}`}>
                  {item}
                </p> 
              )}
            </div>
          </div>
        )}
      </div>
    </StyledExhibitions>
  );
}
 
export default Exhibitions;