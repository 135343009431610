import { Outlet } from "react-router-dom";
import Header from "./Header";

const Layout = ({menuItems, setMenuIsActive}) => {
   return ( 
      <>
         <Header 
            menuItems={menuItems}
            setIsActive={setMenuIsActive}
         />
         <Outlet />
      </>
   );
}
 
export default Layout;