import Main from "../components/Main";
import MyMainSlider from "../components/UI/MyMainSlider";

const HomePage = () => {
   return ( 
      <Main>
         <MyMainSlider />
      </Main>
   );
}
 
export default HomePage;