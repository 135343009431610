import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import App from "./App";
import { menuItems } from "./data";
import ScrollToTop from "./components/ScrollToTop";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const theme = {
   colors: {
      primary: "#fff",
      secondary: "#ffbc00",
   },

   fonts: {
     family: "'Work Sans', sans-serif",
     weight: 300,
     size: "16px",
     lineHeight: 1.4,
   },

   media: {
      tablet: "(min-width: 768px)",
      desktop: "(min-width: 1280px)",
   },
};

const Global = createGlobalStyle`
   * {
      padding: 0;
      margin: 0;
      border: 0; 
   }
   
   *, 
   *:before, 
   *:after {
      box-sizing: border-box; 
   }
   
   :focus, 
   :active {
      outline: none; 
   }
   
   a:focus, 
   a:active {
      outline: none; 
   }
   
   header, 
   nav, 
   main, 
   section, 
   aside, 
   footer, 
   article, 
   figure {
      display: block; 
   }

   html {
      background: #000;
   }
   
   html, 
   body {
      height: 100%;
      width: 100%;
      font-size: 100%;
      line-height: 1;
      font-size: 14px;
      -ms-text-size-adjust: 100%;
      -moz-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%; 
   }

   a, 
   a:visited {
      text-decoration: none; 
   }
   
   a:hover {
      text-decoration: none; 
   }
   
   ul li {
      list-style: none; 
   }
   
   img {
      vertical-align: top; 
   }

   body {
      font-family: ${({theme}) => theme.fonts.family};
   }
   
   .grecaptcha-badge { 
      visibility: hidden; 
   }

   h2 {
     color: ${({theme}) => theme.colors.secondary};
     text-transform: uppercase;
     font-weight: ${({theme}) => theme.fonts.weight};
     font-size: 24px;
     line-height: 30px;
   }

   a {
     color: ${({theme}) => theme.colors.secondary};
   }
`;

ReactDOM.render(
   <GoogleReCaptchaProvider reCaptchaKey="6Ld9EXwfAAAAAFcalCSTV0AD3QNPLsn-7UEqov_z">
      <ThemeProvider theme={theme}>
         <Global />
         <BrowserRouter>
            <ScrollToTop />
            <App menuItems={menuItems} />
         </BrowserRouter>
      </ThemeProvider>
   </GoogleReCaptchaProvider>,
   document.getElementById("root")
);
