import styled from "styled-components";
import { StyledMyNavLink } from "./MyNavLink";

const StyledMyLogo = styled.h1`
   @media ${({ theme }) => theme.media.tablet} {
      margin-bottom: 21px;
   }

   @media ${({ theme }) => theme.media.desktop} {
      margin-left: 28.5px;
      margin-right: 28.5px;
      margin-bottom: 0;

      &:first-child:not(li > &) {
         display: none;
      }
   }

   a {
      text-transform: uppercase;
      font-weight: 100;
      font-size: 29px;
      line-height: 34px;

      @media (hover: hover) {
         &:hover {
            color: ${({ theme }) => theme.colors.primary};
         }
      }

      &.active {
         color: ${({ theme }) => theme.colors.primary};
         font-weight: 100;
      }

      @media ${({ theme }) => theme.media.tablet} {
         font-size: 40px;
         line-height: 47px;
      }
   }
`;

const MyLogo = ({ to, children }) => {
   return (
      <StyledMyLogo>
         <StyledMyNavLink to={to}>{children}</StyledMyNavLink>
      </StyledMyLogo>
   );
};

export default MyLogo;
