import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Layout from "./components/Layout";
import MyMenu from "./components/UI/MyMenu";
import ExhibitionsPage from "./pages/ExhibitionsPage";
import ContactPage from "./pages/ContactPage";
import GalleryPage from "./pages/GalleryPage";
import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFoundPage";
import BioPage from "./pages/BioPage";

const StyledApp = styled.div`
   min-height: 100vh;
   overflow: hidden;
   display: flex;
   flex-direction: column; 
   background-color: #000;
`;

const App = ({menuItems}) => {
   const [menuIsActive, setMenuIsActive] = useState(false);

   return ( 
      <StyledApp>
         <Routes>
            <Route
               path="/"
               element={
                  <Layout 
                     menuItems={menuItems}
                     setMenuIsActive={setMenuIsActive}
                  />
               }
            >
               <Route 
                  index 
                  element={<HomePage />}
               />
               <Route 
                  path="gallery" 
                  element={<Navigate to="/gallery/anthropocene-skulls" replace />}
               />
               <Route 
                  path="gallery/:id" 
                  element={<GalleryPage />}
               />
               <Route 
                  path="bio" 
                  element={<BioPage />}
               />
               <Route 
                  path="exhibitions" 
                  element={<ExhibitionsPage />}
               />
               <Route 
                  path="contact" 
                  element={<ContactPage />}
               />
               <Route 
                  path="*" 
                  element={<NotFoundPage />}
               />
            </Route>
         </Routes>
         <MyMenu 
            items={menuItems}
            isActive={menuIsActive}
            setIsActive={setMenuIsActive}
         />
      </StyledApp>
   );
}
 
export default App;
