import styled from "styled-components";
import Container from "./Container";

const StyledFooter = styled.footer`
   padding: 150px 0 55px;
   text-align: center;
   color: ${props => props.theme.colors.primary};
   font-weight: ${props => props.theme.fonts.weight};
   font-size: ${props => props.theme.fonts.size};
   line-height: 19px;

   span {
      display: block;
   }

   @media ${props => props.theme.media.tablet} {
      span {
         display: inline;
      }
   }
`;

const Footer = props => {
   return ( 
      <StyledFooter {...props}>
         <Container>
            <p>
               <span>&copy; 2022 Ron Garrigues.</span> <span>All rights reserved.</span>
            </p> 
         </Container>
      </StyledFooter>
   );
}
 
export default Footer;