import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/bundle';
import "swiper/css/effect-fade";
import LoadableImage2 from "./LoadableImage2";

import slide_1_1 from "../../assets/anthropocene-skulls/anthropocene-skulls-big/absolut_ron.jpg";
import slide_1_2 from "../../assets/anthropocene-skulls/anthropocene-skulls-big/california_condor.jpg";
import slide_2_1 from "../../assets/anthropocene-skulls/anthropocene-skulls-big/onward_christian_soldiers.jpg";
import slide_2_2 from "../../assets/anthropocene-skulls/anthropocene-skulls-big/rising_waters_ii.jpg";
import slide_3_1 from "../../assets/anthropocene-skulls/anthropocene-skulls-big/two_birds_of_paradise_lost.jpg";
import slide_3_2 from "../../assets/anthropocene-skulls/anthropocene-skulls-big/which_came_last.jpg";

const slides = [
   {
      image_1: slide_1_1,
      image_2: slide_1_2
   },

   {
       image_1: slide_2_1,
       image_2: slide_2_2
   },

   {
       image_1: slide_3_1,
       image_2: slide_3_2
   },
];

const StyledMyMainSlider = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;

   .swiper {
      width: 100%;
      height: 100%;
   }

   .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
   }
`;

const MyMainSlider = () => { 
   return ( 
      <StyledMyMainSlider>
         <Swiper
            modules={[Autoplay, EffectFade]}
            autoplay={{
               delay: 2500,
               disableOnInteraction: false,
            }}
            effect="fade"
            speed={500}
            loop={true}
         >
            {slides.map((slide, index) => 
               <SwiperSlide key={index}>
                  <LoadableImage2 
                     src_1={slide.image_1}
                     src_2={slide.image_2}
                     alt={`slide ${index}`}
                  />
               </SwiperSlide>
            )}
         </Swiper>
      </StyledMyMainSlider>
   );
}
 
export default MyMainSlider;