import styled from "styled-components";
import Container from "./Container";
import { HiOutlineMenu } from "react-icons/hi";
import MyLogo from "./UI/MyLogo";
import MyNavLink from "./UI/MyNavLink";

const StyledHeader = styled.header`
    position: relative;
    z-index: 10;
    padding: 23px 0 40px;

   @media ${props => props.theme.media.tablet} {
      padding: 40px 0 70px;
   }

   @media ${props => props.theme.media.desktop} {
      padding: 40px 0 90px;    
   }

   .header__flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media ${({theme}) => theme.media.tablet} {
         flex-direction: column;
         justify-content: center;
      }
   }

   .burger-icon {
      display: block;
      cursor: pointer;

      @media ${({theme}) => theme.media.tablet} {
         display: none;
      }

      * {
         stroke-width: 1px;
      }
   }

   .menu {
      align-items: center;
      margin: 0 -21.5px;

      li {
         margin: 0 21.5px;
      }
   }

   .menu-tablet {
      display: none;

      @media ${({theme}) => theme.media.tablet} {
         display: flex;
      }

      @media ${({theme}) => theme.media.desktop} {
         display: none;
      }
   }

   .menu-desktop {
      display: none;

      @media ${({theme}) => theme.media.desktop} {
         display: flex;
      }

      li:last-child {
         margin: 0;
      }

      li:nth-child(n + 3):not(:last-child) {
         order: 5;
      }
   }
`;

const Header = ({menuItems, setIsActive, ...props}) => {
   const openMyMenu = () => {
      setIsActive(true);
      document.body.style.overflow = 'hidden';   
   };

   return ( 
      <StyledHeader {...props}>
         <Container>
            <div className="header__flex">
               <MyLogo to="/">Ron Garrigues</MyLogo>
               <HiOutlineMenu 
                  className="burger-icon"
                  size="30px"
                  color="#fff"
                  onClick={openMyMenu}
               />
               <ul className="menu menu-tablet">
                  {menuItems.map(item =>
                     <li key={item.to}>
                        <MyNavLink to={item.to}>
                           {item.content}
                        </MyNavLink>
                     </li>)}
               </ul>
               <ul className="menu menu-desktop">
                  {[...menuItems.map(item =>
                     <li key={item.to}>
                        <MyNavLink  to={item.to}>
                           {item.content}
                        </MyNavLink>
                     </li>),
                     <li key="/">
                        <MyLogo to="/">Ron Garrigues</MyLogo>
                     </li>]}
               </ul>
            </div>
         </Container>
      </StyledHeader>
  );
}
 
export default Header;