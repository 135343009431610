import React from "react";
import styled from "styled-components";

const StyledMyTextrarea = styled.textarea`
   display: block;
   width: 100%;
   height: 100px;
   padding: 10px 15px;
   border: 1px solid #fff;
   background-color: #000;
   color: ${({theme}) => theme.colors.primary};
   font-family: ${({theme}) => theme.fonts.family};
   font-size: 14px;
   resize: none;

   &::placeholder {
      color: ${({theme}) => theme.colors.primary};
   }

   &:focus::placeholder {
      color: transparent;
   }
`;

const MyTextarea = React.forwardRef((props, ref) => {
   return <StyledMyTextrarea {...props} ref={ref} />;
});

export default MyTextarea;
