import { Fragment } from "react";
import styled from "styled-components";
import { bio } from "../data";
import rongarrigues1 from "../assets/rongarrigues1.jpg";

const StyledBio = styled.div`
   display: flex;
   flex-direction: column;
   color: ${({theme}) => theme.colors.primary};
   font-weight: ${({theme}) => theme.fonts.weight};
   font-size: ${({theme}) => theme.fonts.size};
   line-height: 20px;

   @media ${({theme}) => theme.media.tablet} {
      flex-direction: row;
   }

   .bio-image-wrapper {
      margin-bottom: 58px;

      @media ${({theme}) => theme.media.tablet} {
         align-self: flex-start;
         flex: 0 0 auto;
         width: 33.18%;
         margin-right: 40px;
         margin-bottom: 0;
      }

      @media ${({theme}) => theme.media.desktop} {
         margin-right: 78px; 
      }
   }

   .bio-info > div:not(:last-child) {
      margin-bottom: 58px;

      @media ${({theme}) => theme.media.tablet} {
         margin-bottom: 124px;
      }
   }

   img {
    display: block;
    width: 100%;
    height: 100%;
  }

  p {
      margin-bottom: 20px;
      line-height: ${({theme}) => theme.fonts.lineHeight};
  }
`;

const Bio = props => {
  return ( 
    <StyledBio {...props}>
      <div className="bio-image-wrapper">
        <img src={rongarrigues1} alt="Ron Garrigues" />
      </div>
      <div className="bio-info">
        {bio.map((item, index) => 
          <Fragment key={index}>
              {item}
          </Fragment>
        )}
      </div>
    </StyledBio>
  );
}
 
export default Bio;