import { useState } from "react";
import styled from "styled-components";
import MyLightbox from "./UI/MyLightbox";
import MyNavLink from "./UI/MyNavLink";

const StyledGallery = styled.div`
   .sub-menu {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -21.5px 24px;

      @media ${({theme}) => theme.media.tablet} {
         margin-bottom: 47px;
      }

      li {
         margin: 0 21.5px 10px;
      }
   }

   .gallery-items_mobile {
      display: flex;
      flex-direction: column;

      @media ${({theme}) => theme.media.tablet} {
         display: none;
      }

   .gallery-item_mobile {
         position: relative;
         cursor: pointer;
      }

   .gallery-item_mobile:not(:last-child) {
         margin-bottom: 60px;
      }

      .gallery-item-overlay {
         position: absolute;
         top: 0;
         left: 0;
         display: none;
         flex-direction: column;
         justify-content: flex-end;
         width: 100%;
         height: 100%;
         background-color: rgba(0, 0, 0, .5);
         color: ${({theme}) => theme.colors.primary};

         &.gallery-item-overlay_active {
            display: flex;
         }

      .gallery-item-overlay-info {
            padding: 0 12px 42px;
         }

      .gallery-item-overlay-info > div {
            font-weight: ${({theme}) => theme.fonts.weight};
            font-size: 20px;
            line-height: ${({theme}) => theme.fonts.lineHeight};
         }

      .gallery-item-overlay-info > div:first-child {
            margin-bottom: 15px;
            font-weight: bold;
         }
      }
   }

   .gallery-items_tablet {
      display: none;
      flex-direction: row;
      margin: 0 -30px;

      @media ${({theme}) => theme.media.tablet} {
         display: flex;
      }

      @media ${({theme}) => theme.media.desktop} {
         display: none;
      }

      > div {
         flex: 0 1 50%;
         margin: 0 30px;
      }

      .gallery-item_tablet {
         cursor: pointer;
      }

      .gallery-item_tablet:not(:last-child) {
         margin-bottom: 60px;
      }
   }

   .gallery-items_desktop {
      display: none;
      flex-direction: row;
      margin: 0 -35px;

      @media ${({theme}) => theme.media.desktop} {
         display: flex;
      }

      > div {
         flex: 0 1 33.333%;
         margin: 0 35px;
      }

      .gallery-item_desktop {
         cursor: pointer;
      }

      .gallery-item_desktop:not(:last-child) {
         margin-bottom: 70px;
      }
   }

   img {
      display: block;
      width: 100%;
      height: 100%;
   }
`;

const Gallery = ({subMenuItems, galleryItems, ...props}) => {
   const [isInfoVisible, setIsInfoVisible] = useState({
                                                         isVisible: false, 
                                                         item: null
                                                      });

   const [isOpen, setIsOpen] = useState(false);
   const [currentItemIndex, setCurrentItemIndex] = useState(0);
   const [isClicked, setIsClicked] = useState(false);

   const openMyLightbox = (index) => {
      setIsOpen(true);
      setCurrentItemIndex(index);
      setIsClicked(false);
   };

   return ( 
      <StyledGallery {...props}>
         <ul className="sub-menu">
            {subMenuItems.map(item => 
               <li key={item.to}>
                  <MyNavLink 
                     className="submenu-navlink"
                     to={`/gallery/${item.to}`}
                     onClick={() => 
                        setIsInfoVisible({
                           isVisible: false, 
                           item: null
                        })
                     }
                  >
                     {item.content}
                  </MyNavLink>
               </li>
            )}
         </ul>
         <div className="gallery-items_mobile">
            <div>
               {galleryItems.map((item, index) => 
                  <div 
                     className="gallery-item_mobile"
                     key={`${item.category} ${item.name} ${index}`}
                     onClick={() => {
                        if (isInfoVisible.item === index) {
                           setIsInfoVisible({
                              ...isInfoVisible,
                              isVisible: !isInfoVisible.isVisible
                           });
                        } else {
                           setIsInfoVisible({
                              ...isInfoVisible,
                              isVisible: true, 
                              item: index
                           });
                        }
                     }}
                  >
                     <img src={item.images.image_1} alt={item.images.alt} />
                     <div className={`${isInfoVisible.isVisible && isInfoVisible.item === index 
                                       ? 
                                       'gallery-item-overlay gallery-item-overlay_active' 
                                       : 
                                       'gallery-item-overlay'}`}
                     >
                        <div className="gallery-item-overlay-info">
                           <div>{item.name}</div>
                           <div>{item.year}</div>
                           <div>{item.material}</div>
                           <div>{item.dimensions}</div>
                        </div>
                     </div>
                  </div>
               )}
            </div>
         </div>
         <div className="gallery-items_tablet">
            <div>
               {galleryItems.map((item, index) => 
                  (index % 2 === 0)
                     &&
                     <div 
                        className="gallery-item_tablet"
                        key={`${item.category} ${item.name} ${index}`}
                        onClick={() => openMyLightbox(index)}
                     >
                        <img src={item.images.image_1} alt={item.images.alt} />
                     </div>
               )}
            </div>
            <div>
               {galleryItems.map((item, index) => 
                  (index % 2 !== 0)
                     &&
                     <div 
                        className="gallery-item_tablet"
                        key={`${item.category} ${item.name} ${index}`}
                        onClick={() => openMyLightbox(index)}
                     >
                        <img src={item.images.image_1} alt={item.images.alt} />
                     </div>
               )}
            </div>
         </div>
         <div className="gallery-items_desktop">
            <div>
               {galleryItems.map((item, index) => 
                  (index % 3 === 0)
                     &&
                     <div
                        className="gallery-item_desktop" 
                        key={`${item.category} ${item.name} ${index}`}
                        onClick={() => openMyLightbox(index)}
                     >
                        <img src={item.images.image_1} alt={item.images.alt} />
                     </div>
               )}
            </div>
            <div>
               {galleryItems.map((item, index) => 
                  (index % 3 === 1)
                     &&
                     <div 
                        className="gallery-item_desktop" 
                        key={`${item.category} ${item.name} ${index}`}
                        onClick={() => openMyLightbox(index)}
                     >
                        <img src={item.images.image_1} alt={item.images.alt} />
                     </div>
               )}
            </div>
            <div>
               {galleryItems.map((item, index) => 
                  (index % 3 === 2)
                     &&
                     <div 
                        className="gallery-item_desktop" 
                        key={`${item.category} ${item.name} ${index}`}
                        onClick={() => openMyLightbox(index)}
                     >
                        <img src={item.images.image_1} alt={item.images.alt} />
                     </div>
               )}
            </div>
         </div>
         <MyLightbox 
            items={galleryItems}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            currentItemIndex={currentItemIndex}
            setCurrentItemIndex={setCurrentItemIndex}
            isClicked={isClicked}
            setIsClicked={setIsClicked}
         />
      </StyledGallery>
   );
}
 
export default Gallery;